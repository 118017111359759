import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { BloomsBadge } from "../../components/badges/BloomsBadge";
import { Button } from "../../components/fields/Button";
import { BloomsType, Outcome } from "../../types/Outcome";

export interface OutcomeCardProperties {
  outcome: Outcome;
  onRemoveOutcome?: (outcome: Outcome) => void;
  onEditOutcome?: (outcome: Outcome) => void;
  onAddOutcome?: (outcome: Outcome) => void;
  hideBottomBorder?: boolean;
  focusMode?: boolean;
}

export const OutcomeCard = (properties: OutcomeCardProperties): JSX.Element => {
  const {
    outcome,
    onRemoveOutcome,
    onEditOutcome,
    onAddOutcome,
    hideBottomBorder,
    focusMode,
  } = properties;

  return (
    <>
      <div
        className={clsx(
          "flex flex-row px-2",
          hideBottomBorder ? "" : "border-b py-2"
        )}
      >
        <div className="flex-grow pr-4">
          <p className="text-sm text-gray-600 mb-1">
            {outcome.provider && !focusMode && (
              <>
                <span>
                  {outcome.provider}
                  {" | "}
                </span>
              </>
            )}
            {outcome.syllabus && (
              <>
                <span>
                  {outcome.syllabus}
                  {" | "}
                </span>
              </>
            )}
            {outcome.code && (
              <>
                <span className="font-bold uppercase">
                  {outcome.code}
                  {" | "}
                </span>
              </>
            )}
            {outcome.type && outcome.type !== BloomsType.NotSet && (
              <BloomsBadge bloomsType={outcome.type}></BloomsBadge>
            )}
          </p>

          {outcome.description && <p>{outcome.description}</p>}
          {!outcome.description && (
            <p className="text-gray-400">No details yet...</p>
          )}
        </div>
        <div>
          {onAddOutcome && (
            <Button
              eventAction="add-outcome"
              eventCategory="outcomes"
              eventLabel="add-outcome"
              variant="round"
              title="Add Outcome"
              className="my-1"
              onClick={() => {
                onAddOutcome(outcome);
              }}
            >
              <PlusIcon className="h-5 w-5"></PlusIcon>
            </Button>
          )}
          {onEditOutcome && (
            <Button
              eventAction="edit-outcome"
              eventCategory="outcomes"
              eventLabel="edit-outcome"
              variant="round"
              title="Edit Outcome"
              className="my-1"
              onClick={() => {
                onEditOutcome(outcome);
              }}
            >
              <PencilIcon className="h-5 w-5"></PencilIcon>
            </Button>
          )}
          {onRemoveOutcome && (
            <Button
              className="ml-2"
              eventAction="remove-outcome"
              eventCategory="outcomes"
              eventLabel="remove-outcome"
              variant="round"
              onClick={() => onRemoveOutcome(outcome)}
            >
              <TrashIcon className="h-5 w-5"></TrashIcon>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
