import saveAs from "file-saver";
import * as XLSX from "xlsx";
import { ListItem } from "./platform/ListItem";

export enum BloomsType {
  NotSet = "notSet",
  Remember = "remember",
  Understand = "understand",
  Apply = "apply",
  Analyze = "analyze",
  Evaluate = "evaluate",
  Create = "create",
}

// BloomsTypes as an array of typed ListItems
export const BloomsTypesList: ListItem[] = [
  { key: BloomsType.NotSet, label: "Not Set" },
  { key: BloomsType.Remember, label: "Remember" },
  { key: BloomsType.Understand, label: "Understand" },
  { key: BloomsType.Apply, label: "Apply" },
  { key: BloomsType.Analyze, label: "Analyze" },
  { key: BloomsType.Evaluate, label: "Evaluate" },
  { key: BloomsType.Create, label: "Create" },
];

export interface Outcome {
  id?: string;
  providerId?: string;
  provider?: string;
  subject?: string;
  syllabusId?: string;
  syllabus?: string;
  internalId?: string;
  externalId?: string;
  description?: string;
  code?: string;
  type?: BloomsType;
  primary?: boolean;
  secondary?: boolean;
  senior?: boolean;
  y0?: boolean;
  y1?: boolean;
  y2?: boolean;
  y3?: boolean;
  y4?: boolean;
  y5?: boolean;
  y6?: boolean;
  y7?: boolean;
  y8?: boolean;
  y9?: boolean;
  y10?: boolean;
  y11?: boolean;
  y12?: boolean;
}

export const downloadOutcomes = async (outcomes: Outcome[]) => {
  if (!outcomes) {
    return;
  }

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Create a new worksheet
  const worksheet = XLSX.utils.json_to_sheet(outcomes);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Outcomes");

  // Generate spreadsheet bytes
  const spreadsheet = XLSX.write(workbook, {
    type: "array",
    bookType: "xlsx",
  });

  saveAs(
    new Blob([spreadsheet], { type: "application/octet-stream" }),
    `outcomes-${new Date().toISOString()}.xlsx`
  );
};
