import React from "react";
import { SuggestionFeedback } from "../../types/Suggestion";
import { ProfileBadge } from "../profile/ProfileBadge";

// Helper function to get emoji based on feedback type
const getFeedbackEmoji = (feedbackType: string): string => {
  switch (feedbackType) {
    case "positive":
      return "👍";
    case "neutral":
      return "🤔";
    case "negative":
      return "👎";
    default:
      return "";
  }
};

export const SuggestionFeedbackList: React.FC<{
  feedback?: SuggestionFeedback[];
}> = ({ feedback }) => {
  if (!feedback || feedback.length === 0) {
    return null;
  }

  return (
    <div className="mt-6 border-t pt-6">
      <h3 className="font-medium mb-2">Feedback:</h3>
      <div className="space-y-4">
        {feedback.map((item, index) => (
          <div key={index} className={`p-4 rounded text-sm`}>
            <div className="flex justify-between items-start mb-3">
              {/* Profile badge and date row */}
              <div className="flex items-center">
                <ProfileBadge id={item.userId} name={item.userEmail} />
              </div>
              {/* Date in top right */}
              <div className="text-gray-500 text-sm">
                {new Date(item.dateTime).toLocaleDateString()}
              </div>
            </div>
            {/* Feedback content below */}
            <div className="ml-12">
              <div className="flex items-center">
                <span className="text-xl mr-2">
                  {getFeedbackEmoji(item.feedbackType)}
                </span>
                {item.confidence !== undefined && (
                  <span className="text-gray-600 mr-3">
                    ({item.confidence})
                  </span>
                )}
                {item.message && (
                  <p className="mt-0 flex-grow">{item.message}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
