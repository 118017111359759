import clsx from "clsx";
import React from "react";

export interface BadgeProperties {
  text: string;
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  onClick?: () => void;
  // New props (optional for backward compatibility)
  isActive?: boolean;
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info";
  size?: "sm" | "md" | "lg";
  withCount?: number;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export function Badge(properties: BadgeProperties): JSX.Element {
  const {
    text,
    className,
    backgroundColor = "bg-syllabyte-navy", // Keep original default
    foregroundColor = "text-white", // Keep original default
    onClick,
    isActive = false,
    variant,
    size = "md",
    withCount,
    icon,
    disabled = false,
  } = properties;

  // Variant styles
  const variantStyles = {
    default: {
      base: "bg-gray-100 text-gray-800 border border-gray-200",
      hover: "hover:bg-gray-200",
      active: "bg-gray-200 ring-2 ring-gray-400",
    },
    primary: {
      base: "bg-syllabyte-navy text-white border border-syllabyte-navy",
      hover: "hover:bg-syllabyte-navy/90",
      active: "bg-syllabyte-navy/90 ring-2 ring-syllabyte-navy/50",
    },
    secondary: {
      base: "bg-purple-100 text-purple-800 border border-purple-200",
      hover: "hover:bg-purple-200",
      active: "bg-purple-200 ring-2 ring-purple-500",
    },
    success: {
      base: "bg-green-100 text-green-800 border border-green-200",
      hover: "hover:bg-green-200",
      active: "bg-green-200 ring-2 ring-green-500",
    },
    warning: {
      base: "bg-yellow-100 text-yellow-800 border border-yellow-200",
      hover: "hover:bg-yellow-200",
      active: "bg-yellow-200 ring-2 ring-yellow-500",
    },
    danger: {
      base: "bg-red-100 text-red-800 border border-red-200",
      hover: "hover:bg-red-200",
      active: "bg-red-200 ring-2 ring-red-500",
    },
    info: {
      base: "bg-blue-100 text-blue-800 border border-blue-200",
      hover: "hover:bg-blue-200",
      active: "bg-blue-200 ring-2 ring-blue-500",
    },
  };

  // Size styles
  const sizeStyles = {
    sm: "text-xs px-2 py-0.5",
    md: "text-sm px-2.5 py-0.5",
    lg: "text-base px-3 py-1",
  };

  // Interactive states
  const interactiveStyles =
    onClick && !disabled
      ? [
          "cursor-pointer transition-all duration-150",
          !isActive && variant && variantStyles[variant].hover,
        ]
      : [];

  // Active state
  const activeStyles = isActive && variant ? variantStyles[variant].active : "";

  // Disabled state
  const disabledStyles = disabled ? "opacity-50 cursor-not-allowed" : "";

  // Determine color classes
  // If variant is provided, use variant styles, otherwise use backgroundColor/foregroundColor
  const colorClasses = variant
    ? variantStyles[variant].base
    : [backgroundColor, foregroundColor];

  return (
    <span
      className={clsx(
        "inline-flex items-center gap-1.5 whitespace-nowrap rounded-md font-medium",
        sizeStyles[size],
        colorClasses,
        interactiveStyles,
        activeStyles,
        disabledStyles,
        "capitalize",
        className
      )}
      onClick={!disabled ? onClick : undefined}
      role={onClick && !disabled ? "button" : undefined}
      tabIndex={onClick && !disabled ? 0 : undefined}
    >
      {icon && <span className="flex-shrink-0">{icon}</span>}
      <span className={withCount ? "mr-1" : ""}>{text}</span>
      {withCount !== undefined && (
        <span
          className={clsx(
            "inline-flex items-center justify-center rounded-full px-1.5 py-0.5 text-xs font-medium",
            size === "sm" ? "min-w-4 h-4" : "min-w-5 h-5",
            "bg-white text-gray-700"
          )}
        >
          {withCount}
        </span>
      )}
    </span>
  );
}
