import { Toggle } from "../../components/fields/Toggle";
import { CustomList } from "../../types/CustomList";

export interface CustomListSelectedToggleProperties {
  customList: CustomList;
  onRemove: (customList: CustomList) => void;
}

export const CustomListSelectedToggle = (
  properties: CustomListSelectedToggleProperties
): JSX.Element => {
  const { customList, onRemove } = properties;

  return (
    <div className="text-sm font-regular border-b my-2 pb-2">
      <Toggle
        value={true}
        label={customList.title}
        caption={customList.description}
        onChange={() => {
          onRemove(customList);
        }}
      ></Toggle>
    </div>
  );
};
