import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { ProgressBar } from "../../components/fields/ProgressBar";
import { Content } from "../../types/Content";
import {
  ContentTask,
  getTaskStateLabel,
  getTaskTypeLabel,
} from "../../types/ContentTask";
import { getContentTasksInProgressForContent } from "../../util/ApiIntegration";

interface ContentProcessingTasksProps {
  content: Content;
  onAllTasksComplete?: (contentId: string) => void;
}

export function ContentProcessingTasks({
  content,
  onAllTasksComplete,
}: ContentProcessingTasksProps): JSX.Element {
  const [showTasks, setShowTasks] = useState(false);
  const [emptyTasksCount, setEmptyTasksCount] = useState(0);
  const [shouldPollTasks, setShouldPollTasks] = useState(false);
  const completionCalled = useRef(false);

  // Check for tasks if the content was uploaded in the last 3 minutes
  const isRecentEnough = () => {
    if (!content.uploadingDoneAt) return true; // Still uploading, so check

    const now = new Date();
    const uploadTime = new Date(content.uploadingDoneAt);
    const threeMinutesAgo = new Date(now.getTime() - 3 * 60 * 1000);

    return uploadTime > threeMinutesAgo;
  };

  const shouldInitiallyCheckTasks = isRecentEnough();

  // Effect to determine if we should poll for tasks - runs once on mount
  useEffect(() => {
    // Start polling if this is a recently uploaded content
    setShouldPollTasks(shouldInitiallyCheckTasks);

    // If it's a recent upload, automatically show tasks
    if (shouldInitiallyCheckTasks) {
      setShowTasks(true);
    }

    // Reset completion tracker
    completionCalled.current = false;
  }, [shouldInitiallyCheckTasks, content.id]);

  // In-progress tasks query
  const {
    data: inProgressTasks,
    isError,
    isFetching,
  } = useQuery({
    queryKey: ["inProgressTasks", content.id],
    queryFn: async () => {
      if (!content.id) return [];
      try {
        return await getContentTasksInProgressForContent(content.id);
      } catch (error) {
        console.error("Error loading in-progress tasks:", error);
        throw error;
      }
    },
    enabled: shouldPollTasks && !!content.id,
    refetchInterval: shouldPollTasks ? 3000 : false, // Refresh every 3 seconds when polling
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: 2000, // Shorter stale time for more frequent updates
  });

  // Effect to handle empty task responses and stop polling after threshold
  useEffect(() => {
    if (inProgressTasks !== undefined) {
      if (!inProgressTasks || inProgressTasks.length === 0) {
        // Use functional update to avoid dependency on emptyTasksCount
        setEmptyTasksCount(prevCount => {
          const newCount = prevCount + 1;

          // Stop polling after 3 empty responses
          if (newCount >= 3 && !completionCalled.current) {
            setShouldPollTasks(false);

            // Notify parent that all tasks are done
            if (content.id && onAllTasksComplete) {
              completionCalled.current = true;
              onAllTasksComplete(content.id);
            }
          }

          return newCount;
        });
      } else {
        // Reset counter if we get tasks
        setEmptyTasksCount(0);
      }
    }
  }, [inProgressTasks, content.id, onAllTasksComplete]);

  // Only show component if we have actual tasks
  const hasActiveTasks = inProgressTasks && inProgressTasks.length > 0;

  // Don't show anything unless there are actual tasks
  if (!hasActiveTasks && !shouldPollTasks) {
    return <></>;
  }

  // If we're in the initial polling but haven't found tasks yet, don't show UI
  if (!hasActiveTasks && shouldPollTasks && !isError) {
    return <></>; // Silent polling until we find tasks
  }

  // Only show toggle button if we have tasks
  const toggleTasksButton = hasActiveTasks ? (
    <button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setShowTasks(!showTasks);
      }}
      className="text-blue-500 hover:text-blue-700 text-sm flex items-center"
    >
      {showTasks ? (
        <>
          Hide processing tasks <ChevronUpIcon className="h-4 w-4 ml-1" />
        </>
      ) : (
        <>
          Show processing tasks <ChevronDownIcon className="h-4 w-4 ml-1" />
        </>
      )}
    </button>
  ) : null;

  return (
    <div className="mt-4">
      {/* Only show header if we have tasks */}
      {hasActiveTasks && (
        <div className="flex justify-between items-center">
          <h4 className="text-sm font-medium">Processing Tasks</h4>
          {toggleTasksButton}
        </div>
      )}

      {/* Only show task details if we have tasks and they're visible */}
      {hasActiveTasks && showTasks && (
        <div className="mt-2">
          {isError ? (
            <p className="text-sm text-red-500">
              Failed to load processing tasks.
            </p>
          ) : (
            <div className="space-y-3">
              {inProgressTasks.map((task, index) => (
                <TaskItem key={`${task.id || index}`} task={task} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

// Individual task item component
interface TaskItemProps {
  task: ContentTask;
}

function TaskItem({ task }: TaskItemProps): JSX.Element {
  return (
    <div className="bg-gray-50 p-2 rounded">
      <div className="flex justify-between">
        <p className="text-xs font-medium">
          {getTaskTypeLabel(task.task || "")} -{" "}
          {getTaskStateLabel(task.state || "")}
        </p>
        <span className="text-xs text-gray-500">{task.progressPercent}%</span>
      </div>
      <div className="text-xs text-gray-700 mb-1">
        {task.message || "Processing..."}
      </div>
      <ProgressBar
        progress={task.progressPercent || 0}
        max={100}
        paused={task.state === "pending"}
      />
    </div>
  );
}
