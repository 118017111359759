import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useId, useState } from "react";

interface NumberPickerFieldProperties {
  label: string;
  name: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  helper?: string;
  initialValue?: number;
}

export const NumberPickerField: React.FC<NumberPickerFieldProperties> = ({
  label,
  name,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  step = 1,
  disabled = false,
  helper,
  initialValue = 0,
}) => {
  // Ensure initialValue respects min/max
  const safeInitialValue = Math.max(min, Math.min(max, initialValue));
  const [field, meta, helpers] = useField(name);
  const { setFieldError } = useFormikContext();
  const id = useId();
  const decrementId = `${id}-decrement`;
  const incrementId = `${id}-increment`;
  const [isFocused, setIsFocused] = useState(false);

  // Safely get the current value, providing a default if undefined
  const currentValue =
    field.value !== undefined ? field.value : safeInitialValue;

  // Initialize inputValue with the properly handled currentValue
  const [inputValue, setInputValue] = useState(currentValue.toString());

  useEffect(() => {
    // If field.value is undefined or outside bounds, set it to initialValue
    if (field.value === undefined) {
      helpers.setValue(safeInitialValue);
    } else if (field.value < min || field.value > max) {
      // Force clamp values that are out of bounds
      const clampedValue = Math.max(min, Math.min(max, field.value));
      helpers.setValue(clampedValue);
      setInputValue(clampedValue.toString());
    } else {
      setInputValue(field.value.toString());
    }
  }, [field.value, helpers, safeInitialValue, min, max]);

  const validateAndSetValue = useCallback(
    (newValue: number) => {
      // Round to avoid floating point precision issues
      // Determine decimal precision based on step value
      const precision = step.toString().includes(".")
        ? step.toString().split(".")[1].length
        : 0;

      // First ensure the value is clamped within bounds
      const clampedValue = Math.max(min, Math.min(max, newValue));

      // Then round to the appropriate precision
      const roundedValue = Number(clampedValue.toFixed(precision));

      // Check if we've bumped against a limit
      const hitLimit = newValue < min || newValue > max;

      if (hitLimit) {
        setFieldError(name, `Value must be between ${min} and ${max}`);
      } else {
        helpers.setError(undefined);
      }

      // Always use the properly clamped and rounded value
      helpers.setValue(roundedValue);
      setInputValue(roundedValue.toString());
    },
    [min, max, step, helpers, name, setFieldError]
  );

  const increment = useCallback(() => {
    // Use precise addition for decimal values
    const result = parseFloat((Number(currentValue) + step).toFixed(10));
    validateAndSetValue(result);
  }, [currentValue, step, validateAndSetValue]);

  const decrement = useCallback(() => {
    // Use precise subtraction for decimal values
    const result = parseFloat((Number(currentValue) - step).toFixed(10));
    validateAndSetValue(result);
  }, [currentValue, step, validateAndSetValue]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      // Only allow valid numeric input (including negative numbers and decimal point)
      if (/^-?\d*\.?\d*$/.test(newValue)) {
        setInputValue(newValue);

        if (newValue === "" || newValue === "-") {
          helpers.setValue("");
        } else {
          const numberValue = Number.parseFloat(newValue);
          if (!Number.isNaN(numberValue)) {
            // Determine precision based on step
            const precision = step.toString().includes(".")
              ? step.toString().split(".")[1].length
              : 0;

            // Don't round during typing, but ensure we're not storing
            // excessive decimal places that could cause floating point issues
            if (
              newValue.includes(".") &&
              newValue.split(".")[1].length > precision + 2
            ) {
              // Limit excessive precision during typing
              const limitedPrecision = parseFloat(
                numberValue.toFixed(precision + 2)
              );
              helpers.setValue(limitedPrecision);
            } else {
              helpers.setValue(numberValue);
            }
          }
        }
      }
    },
    [helpers, step]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsFocused(false);

      let valueToValidate;

      if (inputValue === "" || inputValue === "-") {
        // Handle empty or minus-only input
        valueToValidate = min >= 0 ? min : 0;
      } else {
        // Parse the input value
        valueToValidate = Number.parseFloat(inputValue);

        // Force clamp to min/max on blur
        if (valueToValidate < min) valueToValidate = min;
        if (valueToValidate > max) valueToValidate = max;
      }

      validateAndSetValue(valueToValidate);
      helpers.setTouched(true);
    }
  };

  const handleFocus = () => setIsFocused(true);

  const buttonStyle = clsx(
    "z-10 bg-white h-full w-20 cursor-pointer flex items-center justify-center",
    {
      "bg-gray-100 cursor-not-allowed": disabled,
    }
  );

  return (
    <div>
      <label htmlFor={id} className="block font-medium mb-1">
        {label}
      </label>
      <div
        role="group"
        aria-labelledby={`${id}-label`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={clsx(
          "flex flex-row h-10 w-full relative mt-1 border rounded-md shadow-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-syllabyte-blue",
          {
            "border-gray-300": !meta.error,
            "border-red-500": meta.error,
            "bg-gray-100": disabled,
            "bg-white": !disabled,
            "ring-2 ring-offset-2 ring-syllabyte-blue": isFocused,
          }
        )}
      >
        <button
          type="button"
          id={decrementId}
          onClick={decrement}
          className={clsx(buttonStyle, "rounded-l-md")}
          disabled={disabled || Number(currentValue) <= min}
          aria-label={`Decrease ${label}`}
          aria-controls={id}
        >
          <MinusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <input
          {...field}
          value={inputValue}
          onChange={handleInputChange}
          type="text"
          inputMode="decimal"
          id={id}
          disabled={disabled}
          aria-invalid={!!meta.error}
          aria-describedby={meta.error ? `${id}-error` : undefined}
          className={clsx(
            "border-0 text-center appearance-none w-full text-base cursor-default block focus:outline-none",
            {
              "bg-gray-100": disabled,
            }
          )}
        />
        <button
          type="button"
          id={incrementId}
          onClick={increment}
          className={clsx(buttonStyle, "rounded-r-md")}
          disabled={disabled || Number(currentValue) >= max}
          aria-label={`Increase ${label}`}
          aria-controls={id}
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      {meta.touched && meta.error && (
        <div
          id={`${id}-error`}
          className="mt-2 text-sm text-red-500"
          aria-live="polite"
        >
          {meta.error}
        </div>
      )}
      {helper && <p className="mt-2 text-sm text-gray-600">{helper}</p>}
    </div>
  );
};

export default NumberPickerField;
