import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useField } from "formik";
import { debounce } from "lodash";
import React, { useCallback, useRef } from "react";

export interface TextInputFieldProperties {
  name: string;
  label?: string;
  rows?: number;
  helper?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  onFinishedInput?: (value: string) => void;
  showError?: boolean;
  debounceTime?: number;
  className?: string;
}

export const TextInputField: React.FC<TextInputFieldProperties> = ({
  name,
  label,
  rows = 1,
  helper,
  placeholder,
  required = false,
  disabled = false,
  error,
  onChange,
  onFinishedInput,
  showError = true,
  debounceTime = 300,
  className,
}) => {
  const [field, meta, helpers] = useField(name);
  const inputReference = useRef<HTMLInputElement | HTMLTextAreaElement | null>(
    null
  );

  const debouncedOnFinishedInput = useRef(
    debounce((value: string) => {
      if (onFinishedInput) onFinishedInput(value);
    }, debounceTime)
  ).current;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onChange(event);
      if (onChange) onChange(event.target.value);
    },
    [field, onChange]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur(event);
      debouncedOnFinishedInput(event.target.value);
    },
    [field, debouncedOnFinishedInput]
  );

  const handleClear = useCallback(() => {
    helpers.setValue("");
    if (onChange) onChange("");
    if (onFinishedInput) onFinishedInput("");
    if (inputReference.current) {
      inputReference.current.focus();
    }
  }, [helpers, onChange, onFinishedInput]);

  const InputComponent = rows > 1 ? "textarea" : "input";

  const showClearButton = rows >= 2 && field.value;

  const inputClassName = clsx(
    "text-gray-900 dark:text-white shadow focus:ring-syllabyte-blue focus:border-syllabyte-blue block w-full disabled:bg-gray-100 dark:disabled:bg-syllabyte-dark-card border-gray-300 dark:border-syllabyte-dark-border rounded-md",
    {
      "resize-y min-h-fit": rows > 1,
      "pr-10": showClearButton,
    },
    className
  );

  const referenceCallback = useCallback(
    (element: HTMLInputElement | HTMLTextAreaElement | null) => {
      inputReference.current = element;
    },
    []
  );

  return (
    <div>
      {label && (
        <label
          htmlFor={`${name}-input`}
          className="block font-medium mb-1 text-gray-900 dark:text-white"
        >
          {label}
          {required && <span className="text-syllabyte-pink ml-1">*</span>}
        </label>
      )}
      <div className="relative">
        <InputComponent
          {...field}
          ref={referenceCallback}
          id={`${name}-input`}
          rows={rows > 1 ? rows : undefined}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          className={inputClassName}
          aria-invalid={!!error}
          aria-describedby={
            `${helper ? `${name}-helper` : ""} ${
              showError && error ? `${name}-error` : ""
            }`.trim() || undefined
          }
        />
        {showClearButton && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-2 top-2 p-1 rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-syllabyte-dark-border focus:outline-none focus:ring-2 focus:ring-syllabyte-blue"
            aria-label="Clear input"
          >
            <XMarkIcon height={16} width={16} />
          </button>
        )}
      </div>
      {helper && (
        <p id={`${name}-helper`} className="mt-2 text-sm text-muted">
          {helper}
        </p>
      )}
      {showError && meta.touched && meta.error && (
        <p id={`${name}-error`} className="mt-2 text-sm text-syllabyte-pink">
          {meta.error}
        </p>
      )}
    </div>
  );
};
