import clsx from "clsx";
import React, { memo } from "react";
import BrainProgress from "./BrainProgress"; // Import the BrainProgress component

type Size = "sm" | "md" | "lg";
type LoaderType = "spinner" | "skeleton" | "brain"; // Add "brain" type
type SkeletonVariant = "text" | "circle" | "rectangle" | "card";
type TopMargin = "none" | "sm" | "md" | "lg";

interface LoadingProperties {
  type?: LoaderType;
  variant?: SkeletonVariant;
  size?: Size;
  label?: string;
  color?: string;
  className?: string;
  width?: string;
  height?: string;
  count?: number;
  topMargin?: TopMargin;
  progress?: number; // Add progress prop for brain animation
  customColors?: {
    primary?: string;
    secondary?: string;
  };
  animationSpeed?: number;
  autoPlay?: boolean; // Add autoPlay prop
  pulseOnComplete?: boolean; // Add control for pulsing when complete
}

const sizeClasses: Record<Size, string> = {
  sm: "w-16 h-16",
  md: "w-24 h-24",
  lg: "w-32 h-32",
};

const spinnerSizeClasses: Record<Size, string> = {
  sm: "h-4 w-4",
  md: "h-6 w-6",
  lg: "h-8 w-8",
};

const brainSizeMap: Record<Size, number> = {
  sm: 60,
  md: 100,
  lg: 150,
};

const topMarginClasses: Record<TopMargin, string> = {
  none: "",
  sm: "mt-4",
  md: "mt-8",
  lg: "mt-12",
};

const SkeletonLine: React.FC<{ className?: string }> = ({ className }) => (
  <div className={clsx("h-2 bg-gray-200 rounded", className)} />
);

const Skeleton: React.FC<LoadingProperties> = memo(
  ({
    variant = "text",
    size = "sm",
    label,
    className,
    width,
    height,
    count = 1,
    topMargin = "sm",
  }) => {
    const SkeletonItem: React.FC = () => {
      if (variant === "card") {
        return (
          <div
            className={clsx(
              "animate-pulse p-4 border-b border-gray-200",
              topMarginClasses[topMargin]
            )}
          >
            <SkeletonLine className="h-6 w-3/4 mb-4" /> {/* Large header */}
            <SkeletonLine className="w-full mb-2" />
            <SkeletonLine className="w-full mb-2" />
            <SkeletonLine className="w-2/3" />
          </div>
        );
      }
      return (
        <div
          className={clsx(
            "animate-pulse bg-gray-200 dark:bg-gray-700",
            topMarginClasses[topMargin],
            {
              "h-4 rounded": variant === "text",
              "rounded-full": variant === "circle",
              rounded: variant === "rectangle",
            },
            variant === "text" ? sizeClasses[size] : undefined,
            className
          )}
          style={{ width, height }}
          role="status"
          aria-label={label || "Loading"}
        />
      );
    };
    return (
      <>
        {Array.from({ length: count }, (_, index) => (
          <SkeletonItem key={index} />
        ))}
      </>
    );
  }
);

const Spinner: React.FC<LoadingProperties> = memo(
  ({
    size = "sm",
    label,
    color = "text-syllabyte-blue",
    className,
    topMargin = "sm",
  }) => (
    <div
      role="status"
      aria-label={label || "Loading"}
      className={clsx(
        "flex items-center justify-center",
        topMarginClasses[topMargin],
        className
      )}
    >
      <svg
        className={clsx("animate-spin", spinnerSizeClasses[size], color)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span className="sr-only">{label || "Loading..."}</span>
    </div>
  )
);

// New BrainLoader component
const BrainLoader: React.FC<LoadingProperties> = memo(
  ({
    size = "sm", // Default to small size
    label,
    className,
    topMargin = "sm",
    progress = 100, // Default progress value
    customColors = { primary: "#06c9a1", secondary: "#007afc" },
    animationSpeed = 1,
    autoPlay = true, // Changed default to true for autoPlay mode
    pulseOnComplete = true, // Default to true to pulse when complete
  }) => (
    <div
      role="status"
      aria-label={label || "Loading"}
      className={clsx(
        "flex items-center justify-center",
        topMarginClasses[topMargin],
        className
      )}
    >
      <BrainProgress
        totalPercent={progress}
        width={brainSizeMap[size]}
        height={brainSizeMap[size]}
        showLabel={size !== "sm"} // Only show percentage label for medium and large sizes
        customColors={customColors}
        animationSpeed={animationSpeed}
        autoPlay={autoPlay}
        pulseOnComplete={pulseOnComplete}
      />
      <span className="sr-only">{label || "Loading..."}</span>
    </div>
  )
);

export const Loading: React.FC<LoadingProperties> = ({
  type = "brain",
  ...properties
}) => {
  if (type === "skeleton") {
    return <Skeleton {...properties} />;
  } else if (type === "brain") {
    return <BrainLoader {...properties} />;
  }
  return <Spinner {...properties} />;
};

export default Loading;
