import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { createContext, useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Limit, Profile } from "../types/platform/Profile";
import { getLimits, getProfile } from "./ApiIntegration";
import { appInsights } from "./config/AppInsights";
import {
  identifyUserInClarity,
  setCustomTagInClarity,
} from "./config/ClarityConfig";

type ProfileContextType = {
  profile: Profile | undefined;
  updateProfile: (profile: Partial<Profile>) => void;
  hasSubscription: boolean;
  platformLimit?: Limit;
  updatePlatformLimit: () => void;
  hitPlatformLimit: boolean;
  logout: () => void;
  refreshProfile: () => Promise<Profile | undefined>;
  isLoading: boolean;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  updateProfile: () => {},
  hasSubscription: false,
  platformLimit: undefined,
  updatePlatformLimit: () => {},
  hitPlatformLimit: false,
  logout: () => {},
  refreshProfile: async () => undefined,
  isLoading: false,
});

export const ProfileProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { inProgress, accounts, instance } = useMsal();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [platformLimit, setPlatformLimit] = useState<Limit>();
  const [hitPlatformLimit, setHitPlatformLimit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updatePlatformLimit = useCallback(() => {
    if (inProgress !== InteractionStatus.None) return;
    if (!instance) return;
    getLimits("platform").then(limits => {
      const limit = limits[0];
      if (!limit) {
        setPlatformLimit(undefined);
        return;
      }
      const rightNow = new Date();
      const limitExpiration = new Date(limit.resetTimeUtc);
      // if the limit has expired, set it to undefined
      if (rightNow > limitExpiration) {
        setPlatformLimit(undefined);
        setHitPlatformLimit(false);
        return;
      }
      if (limit.consumed >= limit.max) {
        setHitPlatformLimit(true);
      } else {
        setHitPlatformLimit(false);
      }
      // if the limit is still valid, set it
      setPlatformLimit({ ...limit });
    });
  }, [profile, inProgress, instance]);

  const refreshProfile = useCallback(async (): Promise<Profile | undefined> => {
    // Wait until MSAL is fully initialized and not in the middle of an interaction
    if (inProgress !== InteractionStatus.None) {
      console.log("MSAL interaction in progress, skipping profile refresh");
      return undefined;
    }

    if (!instance) {
      console.log("No MSAL instance available, skipping profile refresh");
      return undefined;
    }

    if (!accounts || accounts.length === 0) {
      console.log("No accounts available, skipping profile refresh");
      return undefined;
    }

    console.log("Starting profile refresh");
    setIsLoading(true);

    try {
      const latestProfile = await getProfile();

      if (latestProfile && latestProfile.id) {
        console.log("Profile loaded successfully");

        // Analytics and tracking updates
        ReactGA.set({ userId: latestProfile.id });
        appInsights.setAuthenticatedUserContext(latestProfile.id);
        identifyUserInClarity(latestProfile.id);
        setCustomTagInClarity("email", latestProfile.email || "");
        setCustomTagInClarity("subscription", latestProfile.subscription || "");
        setCustomTagInClarity(
          "subscriptionEnd",
          latestProfile.subscriptionEnd
            ? latestProfile.subscriptionEnd.toString()
            : ""
        );
        setCustomTagInClarity("market", latestProfile.market || "");

        // Track login event
        ReactGA.event({
          category: "account",
          action: "login",
          label: "login",
        });

        // Update state
        setProfile(latestProfile);
        updatePlatformLimit();

        return latestProfile;
      }

      console.log("Profile request completed but no valid profile returned");
      return undefined;
    } catch (error) {
      console.error("Error refreshing profile:", error);
      return undefined;
    } finally {
      console.log("Profile refresh complete, resetting loading state");
      setIsLoading(false);
    }
  }, [inProgress, accounts, instance, updatePlatformLimit]);

  const logout = useCallback(() => {
    setProfile(undefined);
    setPlatformLimit(undefined);
    setHitPlatformLimit(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    refreshProfile();
  }, [inProgress, accounts, instance]);

  const updateProfile = useCallback(
    (updatedProfile: Partial<Profile>) => {
      if (profile) {
        setProfile(currentProfile => ({
          ...currentProfile,
          ...updatedProfile,
        }));
      } else {
        setProfile(updatedProfile as Profile);
      }
    },
    [profile]
  );

  const validSubscriptionTypes = new Set([
    "pro",
    "starter",
    "pilot",
    "institution",
  ]);
  const hasSubscription = profile?.subscriptionEnd
    ? validSubscriptionTypes.has(profile.subscription ?? "") &&
      new Date(profile.subscriptionEnd).getTime() >= Date.now()
    : false;

  return (
    <ProfileContext.Provider
      value={{
        profile,
        updateProfile,
        hasSubscription,
        platformLimit,
        updatePlatformLimit,
        hitPlatformLimit,
        logout,
        refreshProfile,
        isLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
