import { CustomList } from "./CustomList";
import { Outcome } from "./Outcome";
import { Syllabus } from "./Syllabus";

export interface ContentTaskRequest {
  id?: string;
  task: string;

  sliceIds?: string[];
  syllabi?: Syllabus[];
  customLists?: CustomList[];
  classificationTargets?: string[];
  outcomes?: Outcome[];

  outputLanguage?: string;
  confidence?: number;
  clearExistingSuggestions?: boolean;
  condenseSlices?: boolean;
  enforceSyllabiCoverage?: boolean;
  size?: number;
  minWidth?: number;
  minHeight?: number;
}

export interface ContentTask {
  id?: string;
  task?: string;
  contentId?: string;
  sliceId?: string;
  syllabi?: Syllabus[];
  customLists?: CustomList[];
  classificationTargets?: string[];
  outcomes?: Outcome[];
  outputLanguage?: string;
  confidence?: number;
  clearExistingSuggestions?: boolean;
  condenseSlices?: boolean;
  enforceSyllabiCoverage?: boolean;
  size?: number;
  max?: number;
  minWidth?: number;
  minHeight?: number;
  state?: string;
  step?: number;
  progressPercent?: number;
  message?: string;
}

// Content Task Types
export const TASK_TYPES = {
  notSet: "Not Set",
  upload: "Content",
  spelling: "Spelling & Grammar",
  classify: "Classification",
  align: "Alignment",
  "extract-outcomes": "Extract Outcomes",
  "expand-print-contractions": "Expand Print Contractions",
  "image-analysis": "Analyse Images",
  "copy-edit": "Copy Edit",
  "copy-develop": "Copy Develop",
  "publishing-metadata": "Extract Publishing Metadata",
};

// Content Task States
export const TASK_STATES = {
  notSet: "Not Set",
  abandoned: "Abandoned",
  ready: "Ready",
  inProgress: "In Progress", // Note: Fixed capitalization for display
  complete: "Complete",
  failed: "Failed",
  broken: "Broken",
};

// Helper function to get task type display name
export function getTaskTypeLabel(type: string): string {
  const normalizedType = type.toLowerCase();
  // Check if it's a direct match
  if (normalizedType in TASK_TYPES) {
    return TASK_TYPES[normalizedType as keyof typeof TASK_TYPES];
  }

  // Check if it contains a known task type
  for (const [key, label] of Object.entries(TASK_TYPES)) {
    if (normalizedType.includes(key)) {
      return label;
    }
  }

  return "Other";
}

// Helper function to get task state display name
export function getTaskStateLabel(state: string): string {
  const normalizedState = state?.toLowerCase() || "notSet";
  return TASK_STATES[normalizedState as keyof typeof TASK_STATES] || state;
}

// Task types that work with syllabi
export const SYLLABUS_TASK_TYPES = ["align"];

// Task types that work with classifications/custom lists
export const CLASSIFICATION_TASK_TYPES = ["classify"];

// Determine the task type from a task object
export function determineTaskType(task: { task?: string }): string {
  const taskName = task.task?.toLowerCase() || "";

  // Check for each known task type
  for (const type of Object.keys(TASK_TYPES)) {
    if (taskName.includes(type)) {
      return type;
    }
  }

  // If we can't determine the type, return the original task name
  // instead of a generic "notSet" to preserve the actual task name
  return taskName || "notSet";
}
