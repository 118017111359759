import clsx from "clsx";
import { Outcome } from "../../types/Outcome";
import { OutcomeCard } from "./OutcomeCard";

export interface OutcomesListProperties {
  outcomes: Outcome[];
  onAddOutcome?: (outcome: Outcome) => void;
  onEditOutcome?: (outcome: Outcome) => void;
  onRemoveOutcome?: (outcome: Outcome) => void;
  tall?: boolean;
}

export const OutcomesList = (
  properties: OutcomesListProperties
): JSX.Element => {
  const { outcomes, onAddOutcome, onEditOutcome, onRemoveOutcome, tall } =
    properties;

  return (
    <>
      {outcomes && outcomes.length > 0 && (
        <ul
          className={clsx(
            "overflow-y-scroll shadow p-2 border bg-white focus:ring-syllabyte-blue focus:border-syllabyte-blue block w-full disabled:bg-gray-100 border-gray-300 rounded-md",
            { "max-h-72": !tall }
          )}
        >
          <>
            {outcomes.map((outcome, index) => (
              <li key={`${outcome.id}-${index}`}>
                <OutcomeCard
                  outcome={outcome}
                  onAddOutcome={onAddOutcome}
                  onEditOutcome={onEditOutcome}
                  onRemoveOutcome={onRemoveOutcome}
                />
              </li>
            ))}
          </>
        </ul>
      )}
    </>
  );
};
