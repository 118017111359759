import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/badges/Badge";
import { IdDisplayHelper } from "../../../components/IdDisplayHelper";
import { Content } from "../../../types/Content";
import {
  formatDateToShortDisplay,
  formatSecondsDuration,
} from "../../../util/Helpers";
import { ContentProcessingTasks } from "../ContentProcessingTasks";
import { ContentCardThumbnail } from "./ContentCardThumbnail";
import { ContentUploadTask } from "./ContentUploadTask";

interface ContentCardProperties {
  content: Content;
  onSelect?: () => void;
  onUploadComplete?: (contentId: string) => void;
  onAllTasksComplete?: (contentId: string) => void;
}

interface ContentMetadata {
  duration: string;
  sizeInMB: string;
  lastUpdated: string;
}

export function ContentCard({
  content,
  onSelect,
  onUploadComplete,
  onAllTasksComplete,
}: ContentCardProperties): JSX.Element {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [initialVisibilityChecked, setInitialVisibilityChecked] =
    useState(false);

  // Computed state for card interaction
  const isUploadComplete = useMemo(() => {
    return (
      content.uploadingDoneAt !== undefined &&
      content.uploadingDoneAt !== "0001-01-01T00:00:00"
    );
  }, [content.uploadingDoneAt]);

  const isWebContent = useMemo(() => {
    return !!(content.url && content.contentType?.includes("html"));
  }, [content.url, content.contentType]);

  // Handle local upload complete
  const handleLocalUploadComplete = (contentId: string) => {
    if (onUploadComplete) {
      onUploadComplete(contentId);
    }
  };

  // Initial visibility check for components that are in viewport on load
  useEffect(() => {
    if (initialVisibilityChecked) return;

    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const isInViewport =
        rect.top >= -100 &&
        rect.left >= 0 &&
        rect.bottom <= window.innerHeight + 100 &&
        rect.right <= window.innerWidth;

      if (isInViewport) {
        setIsVisible(true);
      }

      setInitialVisibilityChecked(true);
    }
  }, [initialVisibilityChecked]);

  // Set up intersection observer for visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: "100px", // Load a bit before it becomes visible
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  // Compute metadata once when content changes
  const metadata = useMemo<ContentMetadata>(() => {
    const meta = {
      duration: "",
      sizeInMB: "",
      lastUpdated: "",
    };

    if (content.byteSize && content.byteSize > 0) {
      meta.sizeInMB = (content.byteSize / (1024 * 1024)).toFixed(2) + " MB";
    }

    if (content.durationSeconds && content.durationSeconds > 0) {
      meta.duration = formatSecondsDuration(content.durationSeconds);
    }

    if (
      content.lastUpdated &&
      content.lastUpdated.toString() !== "0001-01-01T00:00:00"
    ) {
      meta.lastUpdated = formatDateToShortDisplay(
        new Date(content.lastUpdated)
      );
    }

    return meta;
  }, [content]);

  return (
    <div
      id={content.id}
      ref={cardRef}
      className={`bg-white rounded-xl shadow-md overflow-hidden mb-4 transition-all duration-300 ${
        isUploadComplete
          ? "cursor-pointer hover:shadow-lg"
          : "cursor-not-allowed opacity-90"
      }`}
      onClick={isUploadComplete ? onSelect : undefined}
    >
      <div className="md:flex">
        <div className="md:flex-shrink-0 bg-gray-200">
          <ContentCardThumbnail
            content={content}
            isVisible={isVisible}
            isWebContent={isWebContent}
            isUploadComplete={isUploadComplete}
          />
        </div>

        <div className="p-6 flex-grow flex flex-col">
          {/* System metadata section */}
          <div className="flex gap-2 items-baseline mb-2 flex-wrap">
            <IdDisplayHelper label="Content Id" id={content.id || ""} />
            {content.enabled && (
              <Badge
                backgroundColor="bg-gray-100"
                foregroundColor=""
                text="Enabled"
              />
            )}
            {!isUploadComplete && (
              <Badge text="Uploading..." variant="warning" />
            )}
          </div>

          {/* File metadata section */}
          <div className="text-gray-500 text-xs uppercase space-x-2">
            {content.fileExtension && <span>{content.fileExtension}</span>}
            {metadata.sizeInMB && <span>{metadata.sizeInMB}</span>}
            {metadata.lastUpdated && <span>{metadata.lastUpdated}</span>}
            {metadata.duration && <span>{metadata.duration}</span>}
          </div>

          {/* Title section */}
          {isUploadComplete ? (
            <Link
              to={`/content/editor/${content.id}`}
              className="block font-bold text-black hover:underline text-xl mb-2 mt-2"
            >
              {content.title || "No Title"}
            </Link>
          ) : (
            <span className="block font-bold text-gray-700 text-xl mb-2 mt-2">
              {content.title || "No Title"}
            </span>
          )}

          {/* Description section */}
          {content.description && (
            <p className="text-sm mt-1 mb-2">
              {content.description.length > 220
                ? `${content.description.slice(0, 220)}...`
                : content.description}
            </p>
          )}

          {/* URL section */}
          {content.url && (
            <p className="text-sm mt-1 mb-2">
              <Link
                to={content.url}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:underline flex items-center"
              >
                <span className="truncate mb-1 max-w-md">
                  {content.url.length > 140
                    ? `${content.url.slice(0, 140)}...`
                    : content.url}
                </span>
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 inline ml-1 flex-shrink-0"
                  title={content.url}
                />
              </Link>
            </p>
          )}

          {/* User tags section - positioned after description */}
          <div className="flex flex-wrap gap-2 mt-1 mb-2">
            {content.isbn && <Badge text={`ISBN: ` + content.isbn} />}
            {content.doi && <Badge text={`DOI: ` + content.doi} />}

            {content.tags && content.tags.length > 0 && (
              <>
                {content.tags.map((tag, index) => (
                  <Badge key={`tag-${index}`} text={tag} />
                ))}
              </>
            )}
          </div>

          {/* Task status section */}
          <div className="mt-auto">
            {/* Upload Task Component - only shown when upload is in progress */}
            {!isUploadComplete && isVisible && (
              <ContentUploadTask
                content={content}
                onUploadComplete={handleLocalUploadComplete}
              />
            )}

            {/* Processing Tasks Component - Only shown for uploads that are complete */}
            {isUploadComplete && isVisible && (
              <ContentProcessingTasks
                content={content}
                onAllTasksComplete={onAllTasksComplete}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
