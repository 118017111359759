import React from "react";

interface TrafficLightProperties {
  value: number;
}

const TrafficLight: React.FC<TrafficLightProperties> = ({ value }) => {
  let color;

  if (value < 0.4) {
    color = "bg-red-500";
  } else if (value < 0.7) {
    color = "bg-yellow-500";
  } else {
    color = "bg-green-500";
  }

  return <div className={`h-6 w-6 rounded-full ${color}`} />;
};

export default TrafficLight;
