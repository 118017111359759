import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Disclosure, DisclosureButton } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavItem } from "../../types/platform/NavItem";
import {
  BOOKING_FORM_URL,
  SYLLABYTE_LOGO_DARK_URL,
  SYLLABYTE_LOGO_LIGHT_URL,
} from "../../util/Constants";
import { useDarkMode } from "../../util/DarkModeContext";
import { ProfileContext } from "../../util/ProfileContext";
import { Button } from "../fields/Button";
import { FlyoutItem } from "./FlyoutItem";
import { NavLinkItem } from "./NavLinkItem";
import { ProfileMenu } from "./ProfileMenu";

interface NavBarProperties {
  navItems: NavItem[];
  fullWidth?: boolean;
}

export const defaultLinkStyle =
  "text-center text-md border-b-2 font-medium px-1 py-1 mb-1 hover:border-syllabyte-navy dark:hover:border-white";
export const mobileLinkStyle =
  "text-md block font-medium rounded-md px-3 py-2 hover:bg-gray-50";

export const NavBar: React.FC<NavBarProperties> = React.memo(
  ({ navItems, fullWidth = false }) => {
    const { profile, isLoading, refreshProfile } = useContext(ProfileContext);
    const { isDarkMode } = useDarkMode();
    const location = useLocation();
    // Get MSAL state directly in the component
    const { inProgress, accounts } = useMsal();

    useEffect(() => {
      const verifyAuth = async () => {
        // Only attempt profile refresh on dashboard when:
        // 1. MSAL is not in the middle of an interaction
        // 2. We have accounts available
        // 3. No profile is loaded yet
        // 4. We're not already loading
        if (
          location.pathname === "/dashboard" &&
          inProgress === InteractionStatus.None &&
          accounts &&
          accounts.length > 0 &&
          !profile &&
          !isLoading
        ) {
          console.log("MSAL ready, attempting profile refresh from NavBar");
          try {
            await refreshProfile();
          } catch (error) {
            console.error("Error refreshing profile:", error);
          }
        }
      };

      verifyAuth();
    }, [
      location.pathname,
      profile,
      refreshProfile,
      isLoading,
      inProgress,
      accounts,
    ]);

    const renderNavItems = useCallback(
      (isMobile: boolean, close: () => void) =>
        navItems.map(item => {
          if (item.path) {
            return (
              <NavLinkItem
                key={item.label}
                item={item}
                onClick={close}
                className={isMobile ? mobileLinkStyle : defaultLinkStyle}
              />
            );
          }
          if (item.flyout) {
            return (
              <FlyoutItem
                key={item.label}
                item={item}
                onClose={close}
                isMobile={isMobile}
              />
            );
          }
          return <div key={item.label}>Invalid Nav Item</div>;
        }),
      [navItems]
    );

    // Show connecting only when actively loading and no profile yet
    const showConnecting = isLoading && !profile;

    return (
      <Disclosure as="nav">
        {({ open, close }) => (
          <>
            <div
              className={`${
                fullWidth
                  ? "w-full px-4 sm:px-8"
                  : "max-w-7xl mx-auto xs:px-4 sm:px-8"
              }`}
            >
              <div className="flex items-center justify-between h-20">
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-6">
                    <Link data-id="nav-logo-home" to="/" onClick={() => close}>
                      <img
                        className="h-12 sm:h-14 w-auto"
                        src={
                          isDarkMode
                            ? SYLLABYTE_LOGO_LIGHT_URL
                            : SYLLABYTE_LOGO_DARK_URL
                        }
                        alt="syllabyte.ai"
                      />
                    </Link>
                  </div>
                </div>
                <div className="flex">
                  <div className="hidden md:block">
                    <div className="flex space-x-4 mr-6">
                      {renderNavItems(false, close)}
                    </div>
                  </div>

                  {profile ? (
                    <ProfileMenu
                      isDropDown={true}
                      profile={profile}
                      onClose={close}
                    />
                  ) : (
                    <>
                      <Button
                        to={BOOKING_FORM_URL}
                        className="text-center xs:hidden sm:block"
                        loadingColor="white"
                        variant="syllabyteBlue"
                        onClick={close}
                      >
                        Book a Demo
                      </Button>
                      <Button
                        to="/login"
                        className="ml-6 text-center"
                        isLoading={showConnecting}
                        loadingColor={isDarkMode ? "white" : "gray"}
                        variant="greyOutline"
                        onClick={close}
                      >
                        {showConnecting ? "Connecting..." : "Login / Register"}
                      </Button>
                    </>
                  )}
                </div>
                <div className="-mr-2 flex md:hidden">
                  <DisclosureButton className="inline-flex items-center justify-center p-2 rounded-md text-syllabyte-blue hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-syllabyte-blue">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {renderNavItems(true, close)}
                {!profile && (
                  <>
                    <Button
                      to={BOOKING_FORM_URL}
                      className="text-center w-full mt-4"
                      loadingColor="white"
                      variant="syllabyteBlue"
                      onClick={close}
                    >
                      Book a Demo
                    </Button>
                    <Link
                      to="/login"
                      className="cursor-pointer text-center dark:text-white mt-2 w-full flex items-center justify-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium"
                      onClick={() => close()}
                    >
                      {showConnecting ? "Connecting..." : "Login / Register"}
                    </Link>
                  </>
                )}
              </div>
              {profile && (
                <ProfileMenu
                  isDropDown={false}
                  profile={profile}
                  onClose={close}
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
);

NavBar.displayName = "NavBar";
