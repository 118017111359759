import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { TextInputField } from "./TextInputField";

interface SearchFormValues {
  searchText: string;
}

interface SearchProperties {
  onSearch: (searchText: string) => void;
  onClear: () => void;
  searchButtonRequired?: boolean;
  placeHolder?: string;
  initialSearchText?: string;
}

export const Search: React.FC<SearchProperties> = ({
  onSearch,
  onClear,
  placeHolder = "Search...",
  searchButtonRequired = false,
  initialSearchText = "",
}) => {
  const initialValues: SearchFormValues = { searchText: initialSearchText };

  const handleSubmit = (
    values: SearchFormValues,
    { setSubmitting }: FormikHelpers<SearchFormValues>,
  ) => {
    onSearch(values.searchText);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form className="space-y-4">
          <div className="relative">
            <TextInputField
              name="searchText"
              placeholder={placeHolder}
              className="pl-10 mb-2 pr-10"
              onChange={value => {
                setFieldValue("searchText", value);
                if (!searchButtonRequired) {
                  onSearch(value);
                }
              }}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            {values.searchText && (
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                onClick={() => {
                  setFieldValue("searchText", "");
                  onClear();
                }}
              >
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
          {searchButtonRequired && (
            <div className="flex space-x-2">
              <Button
                type="submit"
                variant="syllabyteBlue"
                className="flex-grow"
                onClick={submitForm}
              >
                Search
              </Button>
              {values.searchText && (
                <Button
                  type="button"
                  variant="greyOutline"
                  onClick={() => {
                    setFieldValue("searchText", "");
                    onClear();
                  }}
                >
                  Clear
                </Button>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
