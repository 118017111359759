import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { Dropdown } from "../../components/fields/Dropdown";
import { Toggle } from "../../components/fields/Toggle";
import { Loading } from "../../components/Loading";
import { CustomList } from "../../types/CustomList";
import { ListItem } from "../../types/platform/ListItem";
import { Profile } from "../../types/platform/Profile";
import {
  getCustomListOptions,
  getCustomLists,
  getPublicProfile,
} from "../../util/ApiIntegration";
import { ProfileContext } from "../../util/ProfileContext";
import { CustomListSelectedToggle } from "./CustomListSelectedToggle";

export interface CustomListPickerProperties {
  label?: string;
  userDefaultsOnly: boolean;
  multipleSelectMode?: boolean;
  selectedLists?: CustomList[];
  onSelectCustomList?: (customList?: CustomList) => void;
  onRemoveCustomList?: (customList: CustomList) => void;
}

const useCustomListOptions = (userDefaultsOnly: boolean) => {
  const { profile } = useContext(ProfileContext);
  const queryKey = useMemo(
    () => ["customListOptions", userDefaultsOnly],
    [userDefaultsOnly]
  );

  return useQuery({
    queryKey,
    queryFn: async () => {
      // If userDefaultsOnly is true, use the original getCustomLists method
      // which only returns user-owned lists
      if (userDefaultsOnly) {
        return getCustomLists();
      }
      // Otherwise use getCustomListOptions which returns both user and organization lists
      else {
        return getCustomListOptions();
      }
    },
    enabled: profile !== undefined,
    refetchOnWindowFocus: false,
  });
};

// Helper function to load owner profiles efficiently
const loadOwnerProfiles = async (
  ownerIds: string[]
): Promise<Map<string, Profile>> => {
  // Get only unique owner IDs to avoid duplicate requests
  const uniqueOwnerIds = [...new Set(ownerIds)];
  const profilesMap = new Map<string, Profile>();

  // Load profiles in parallel
  const profilePromises = uniqueOwnerIds.map(async id => {
    try {
      // if it's no id or a shit id just reutrn it
      if (!id || id.includes(" ")) {
        return { id } as Profile;
      }

      const profile = await getPublicProfile(id);
      profilesMap.set(id, profile);
      return profile;
    } catch (error) {
      console.error(`Failed to load profile for ${id}:`, error);
      // Create a minimal profile with just the ID if the fetch fails
      profilesMap.set(id, { id } as Profile);
      return { id } as Profile;
    }
  });

  await Promise.all(profilePromises);
  return profilesMap;
};

// Function to get custom list options for a provider
const deriveCustomListOptions = (
  customListsData: CustomList[],
  providerKey: string
): ListItem[] => {
  return customListsData
    .filter(customList => customList.ownerId === providerKey)
    .filter(
      (customList, index, self) =>
        index === self.findIndex(cl => cl.id === customList.id)
    )
    .map(customList => ({
      key: customList.id || "",
      label: customList.title || "",
      description: customList.description || "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const CustomListPicker = (
  properties: CustomListPickerProperties
): JSX.Element => {
  const {
    label = "Custom Lists",
    userDefaultsOnly,
    multipleSelectMode = false,
    selectedLists = [],
    onSelectCustomList,
    onRemoveCustomList,
  } = properties;

  const { profile } = useContext(ProfileContext);
  const [selectedProviderKey, setSelectedProviderKey] = useState<string>("");

  // Use React Query to handle profile loading and caching
  const {
    data: customListsData,
    isLoading: isLoadingCustomLists,
    error: customListsError,
  } = useCustomListOptions(userDefaultsOnly);

  // Extract unique owner IDs from custom lists
  const ownerIds = useMemo(() => {
    if (!customListsData) return [];
    const ids = new Set<string>();
    customListsData.forEach(list => {
      if (list.ownerId) ids.add(list.ownerId);
    });
    return Array.from(ids);
  }, [customListsData]);

  // Use React Query to fetch and cache owner profiles
  const { data: ownerProfiles, isLoading: isLoadingProfiles } = useQuery({
    queryKey: ["ownerProfiles", ownerIds],
    queryFn: async () =>
      ownerIds.length > 0
        ? loadOwnerProfiles(ownerIds)
        : new Map<string, Profile>(),
    enabled: ownerIds.length > 0,
    staleTime: 5 * 60 * 1000, // Cache profiles for 5 minutes
  });

  // Generate provider options
  const providerOptions = useMemo(() => {
    if (!customListsData || !ownerProfiles || !profile) return [];

    const providers = Array.from(
      new Set(customListsData.map(list => list.ownerId || "").filter(id => id))
    );

    return providers
      .map(id => {
        const ownerProfile = ownerProfiles.get(id);
        return {
          key: id,
          label: ownerProfile
            ? ownerProfile.name || ownerProfile.email || ownerProfile.id || id
            : id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [customListsData, ownerProfiles, profile]);

  const customListOptions = useMemo(
    () =>
      customListsData && selectedProviderKey
        ? deriveCustomListOptions(customListsData, selectedProviderKey)
        : [],
    [customListsData, selectedProviderKey]
  );

  const handleCustomListToggle = (customList: CustomList) => {
    if (!onSelectCustomList) return;

    const isSelected = selectedLists.some(cl => cl.id === customList.id);

    if (isSelected) {
      onRemoveCustomList?.(customList);
    } else {
      onSelectCustomList(customList);
    }

    ReactGA.event({
      category: "customLists",
      action: `customLists-${isSelected ? "deselect" : "select"}-${customList.id}`,
      label: `customLists-${isSelected ? "deselect" : "select"}-${customList.id}`,
    });
  };

  // Set current user as default provider if no provider selected yet
  useEffect(() => {
    if (profile && providerOptions.length > 0 && !selectedProviderKey) {
      // Try to find the current user's provider option
      const userOption = providerOptions.find(
        option => option.key === profile.id
      );
      if (userOption) {
        setSelectedProviderKey(userOption.key);
      } else if (providerOptions.length > 0) {
        // If user option not found, select the first provider
        setSelectedProviderKey(providerOptions[0].key);
      }
    }
  }, [profile, providerOptions, selectedProviderKey]);

  const isLoading = isLoadingCustomLists || isLoadingProfiles;

  if (isLoading && (!customListsData || providerOptions.length === 0)) {
    return <Loading />;
  }

  if (customListsError) {
    return <div>Error loading custom lists: {customListsError.message}</div>;
  }

  return (
    <div className="space-y-4">
      {/* Provider Selection */}
      {providerOptions.length > 0 && (
        <Dropdown
          label={label}
          name="providers"
          value={selectedProviderKey}
          options={providerOptions}
          onChange={selectedKey => {
            setSelectedProviderKey(selectedKey);
            if (!multipleSelectMode && onSelectCustomList) {
              onSelectCustomList();
            }
            ReactGA.event({
              category: "customLists",
              action: `customLists-change-provider-${selectedKey}`,
              label: `customLists-change-provider-${selectedKey}`,
            });
          }}
        />
      )}

      <p className="text-sm text-muted mb-2">
        Select custom lists to classify your content against
      </p>

      {/* Custom List Selection */}
      {selectedProviderKey && customListOptions.length > 0 && (
        <div className="mt-2">
          {multipleSelectMode ? (
            <div className="overflow-y-auto border border-gray-300 p-4 rounded-md bg-white shadow-sm dark:bg-syllabyte-dark-card dark:border-syllabyte-dark-border">
              {customListOptions.map(item => (
                <div
                  key={item.key}
                  className="py-2 border-b last:border-b-0 dark:border-syllabyte-dark-border"
                >
                  <Toggle
                    label={item.label}
                    value={selectedLists?.some(
                      customList => customList.id === item.key
                    )}
                    onChange={() => {
                      const customList = customListsData?.find(
                        cl => cl.id === item.key
                      );
                      if (customList) {
                        handleCustomListToggle(customList);
                      }
                    }}
                  />
                  {item.description && (
                    <p className="text-sm text-muted ml-6">
                      {item.description}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Dropdown
              label="Custom Lists"
              name="customLists"
              helper="Select a custom list to classify your content against"
              value={selectedLists?.[0]?.id || ""}
              options={customListOptions}
              onChange={selectedKey => {
                const customList = customListsData?.find(
                  cl => cl.id === selectedKey
                );
                if (customList && onSelectCustomList) {
                  onSelectCustomList(customList);
                }
              }}
            />
          )}
        </div>
      )}

      {/* Selected Custom Lists Display */}
      {multipleSelectMode && selectedLists.length > 0 && (
        <div className="my-4 rounded-md">
          <h3 className="font-medium mb-1">Selected Lists</h3>
          <p className="text-sm text-muted">
            That we'll classify your content against
          </p>
          <div className="mt-4 space-y-2">
            {selectedLists.map(customList => (
              <CustomListSelectedToggle
                key={customList.id}
                customList={customList}
                onRemove={onRemoveCustomList || (() => {})}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
