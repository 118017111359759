import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Button } from "../../components/fields/Button";
import { Modal } from "../../components/Modal";
import { CustomListPicker } from "../../features/custom-lists/CustomListPicker";
import { OutcomesPicker } from "../../features/outcomes/OutcomesPicker";
import { ContentTask } from "../../types/ContentTask";
import { CustomList } from "../../types/CustomList";
import { Outcome } from "../../types/Outcome";
import { Suggestion } from "../../types/Suggestion";
import { Syllabus } from "../../types/Syllabus";

interface SuggestionCreatorProperties {
  tasks?: ContentTask[];
  onSuggested?: (suggestion: Suggestion) => void;
  buttonVariant:
    | "link"
    | "syllabyteBlueLarge"
    | "syllabyteBlue"
    | "syllabyteGreen"
    | "syllabyteGreenLarge";
  wide?: boolean;
  text?: string;
}

export function SuggestionCreator({
  tasks,
  onSuggested,
  buttonVariant,
  text,
  wide,
}: SuggestionCreatorProperties): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detectedSyllabi, setDetectedSyllabi] = useState<Syllabus[]>([]);
  const [detectedCustomLists, setDetectedCustomLists] = useState<CustomList[]>(
    []
  );
  const [suggestionType, setSuggestionType] =
    useState<string>("classification");

  // For classification suggestion
  const [selectedCustomList, setSelectedCustomList] = useState<
    CustomList | undefined
  >();
  const [selectedListItem, setSelectedListItem] = useState<string>("");
  const [classificationText, setClassificationText] = useState<string>("");
  const [classificationJustification, setClassificationJustification] =
    useState<string>("");

  // For outcome suggestion
  const [selectedSyllabus, setSelectedSyllabus] = useState<
    Syllabus | undefined
  >();
  const [selectedOutcome, setSelectedOutcome] = useState<Outcome | undefined>();
  const [outcomeJustification, setOutcomeJustification] = useState<string>("");

  useEffect(() => {
    if (!tasks) {
      return;
    }

    // Extract unique syllabi and custom lists from tasks
    const syllabi: Syllabus[] = [];
    const customLists: CustomList[] = [];
    const syllabusMap = new Map<string, Syllabus>();
    const customListMap = new Map<string, CustomList>();

    tasks.forEach(task => {
      if (task.syllabi && task.syllabi.length > 0) {
        task.syllabi.forEach(syllabus => {
          if (syllabus.id && !syllabusMap.has(syllabus.id)) {
            syllabusMap.set(syllabus.id, syllabus);
            syllabi.push(syllabus);
          }
        });
      }

      if (task.customLists && task.customLists.length > 0) {
        task.customLists.forEach(customList => {
          if (customList.id && !customListMap.has(customList.id)) {
            customListMap.set(customList.id, customList);
            customLists.push(customList);
          }
        });
      }
    });

    setDetectedSyllabi(syllabi);
    setDetectedCustomLists(customLists);

    // Set defaults if available
    if (syllabi.length > 0) {
      setSelectedSyllabus(syllabi[0]);
    }

    if (customLists.length > 0) {
      setSelectedCustomList(customLists[0]);
    }
  }, [tasks]);

  const handleClassificationSubmit = () => {
    if (!selectedCustomList || !selectedListItem || !classificationText) {
      return;
    }

    const newSuggestion: Suggestion = {
      suggestionType: "classify",
      suggested: classificationText,
      justification: classificationJustification,
      confidence: 1.0, // User-created suggestions get high confidence
    };

    if (onSuggested) {
      onSuggested(newSuggestion);
    }

    // Reset form and close modal
    setClassificationText("");
    setClassificationJustification("");
    setSelectedListItem("");
    setIsModalOpen(false);
  };

  const handleOutcomeSubmit = () => {
    if (!selectedOutcome) {
      return;
    }

    const newSuggestion: Suggestion = {
      suggestionType: "align",
      justification: outcomeJustification,
      confidence: 1.0, // User-created suggestions get high confidence
      outcome: selectedOutcome,
    };

    if (onSuggested) {
      onSuggested(newSuggestion);
    }

    // Reset form and close modal
    setOutcomeJustification("");
    setSelectedOutcome(undefined);
    setIsModalOpen(false);
  };

  const handleCustomListSelect = (customList?: CustomList) => {
    setSelectedCustomList(customList);
    setSelectedListItem(""); // Reset the selected item when changing lists
  };

  const handleOutcomeSelect = (outcome: Outcome) => {
    setSelectedOutcome(outcome);
  };

  // Generate custom list item options if a custom list is selected
  const customListItemOptions =
    selectedCustomList?.items?.map(item => ({
      key: item || "",
      label: item || "",
    })) || [];

  return (
    <>
      <div className="text-right">
        <Button
          type="button"
          disabled={true}
          variant={buttonVariant}
          className={wide ? "w-full" : ""}
          onClick={() => setIsModalOpen(true)}
        >
          <PlusIcon className="h-5 w-5 inline-block mr-2" aria-hidden="true" />
          {text ?? "Add Suggestion"}
        </Button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add your own suggestion"
        description="AI miss something? Add your own suggestion to improve the content."
        size="4xl"
        showCancel={true}
      >
        <div className="mt-4 space-y-4">
          <div className="form-control w-full">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Suggestion Type
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              value={suggestionType}
              onChange={e => {
                setSuggestionType(e.target.value);
                // Reset form fields when switching types
                if (e.target.value === "classification") {
                  setSelectedOutcome(undefined);
                  setOutcomeJustification("");
                } else {
                  setSelectedListItem("");
                  setClassificationText("");
                  setClassificationJustification("");
                }
              }}
            >
              <option value="classification">Classification Suggestion</option>
              <option value="outcome">Outcome Suggestion</option>
            </select>
          </div>

          {/* Classification Suggestion Form */}
          {suggestionType === "classification" && (
            <div className="space-y-4">
              {detectedCustomLists && detectedCustomLists.length > 0 ? (
                <>
                  <h3 className="text-lg font-medium">
                    Create Classification Suggestion
                  </h3>

                  <div className="space-y-4">
                    <CustomListPicker
                      label="Select Custom List"
                      userDefaultsOnly={false}
                      selectedLists={
                        selectedCustomList ? [selectedCustomList] : []
                      }
                      onSelectCustomList={handleCustomListSelect}
                    />

                    {selectedCustomList && customListItemOptions.length > 0 && (
                      <div className="form-control w-full">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Select Item from Custom List
                        </label>
                        <select
                          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                          value={selectedListItem}
                          onChange={e => setSelectedListItem(e.target.value)}
                        >
                          <option value="">Select an item...</option>
                          {customListItemOptions.map(option => (
                            <option key={option.key} value={option.key}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    <div className="form-control w-full">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Suggested Text
                      </label>
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        value={classificationText}
                        onChange={e => setClassificationText(e.target.value)}
                        rows={3}
                        placeholder="Enter your suggested classification text..."
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Justification (Optional)
                      </label>
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        value={classificationJustification}
                        onChange={e =>
                          setClassificationJustification(e.target.value)
                        }
                        rows={2}
                        placeholder="Why is this classification appropriate?"
                      />
                    </div>

                    <div className="flex justify-end">
                      <Button
                        variant="syllabyteGreen"
                        onClick={handleClassificationSubmit}
                        disabled={
                          !selectedCustomList ||
                          !selectedListItem ||
                          !classificationText
                        }
                      >
                        Submit Classification Suggestion
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="p-4 bg-gray-100 rounded-md">
                  <p className="text-center text-gray-600">
                    No custom lists available for classification suggestions.
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Outcome Suggestion Form */}
          {suggestionType === "outcome" && (
            <div className="space-y-4">
              {detectedSyllabi && detectedSyllabi.length > 0 ? (
                <>
                  <h3 className="text-lg font-medium">
                    Create Outcome Suggestion
                  </h3>

                  <div className="space-y-4">
                    <div className="form-control w-full">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Select Syllabus
                      </label>
                      <select
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        value={selectedSyllabus?.id || ""}
                        onChange={e => {
                          const syllabus = detectedSyllabi.find(
                            s => s.id === e.target.value
                          );
                          setSelectedSyllabus(syllabus);
                          setSelectedOutcome(undefined);
                        }}
                      >
                        <option value="">Select a syllabus...</option>
                        {detectedSyllabi.map(syllabus => (
                          <option key={syllabus.id} value={syllabus.id || ""}>
                            {syllabus.title || "Untitled Syllabus"}
                          </option>
                        ))}
                      </select>
                    </div>

                    {selectedSyllabus && (
                      <div className="border border-gray-200 rounded-md p-4">
                        <OutcomesPicker
                          syllabus={selectedSyllabus}
                          onSelectOutcome={handleOutcomeSelect}
                          tall={true}
                        />
                      </div>
                    )}

                    {selectedOutcome && (
                      <div className="p-3 bg-green-50 border border-green-200 rounded-md">
                        <h4 className="font-medium">Selected Outcome:</h4>
                        <p className="mt-1">{selectedOutcome.description}</p>
                        {selectedOutcome.code && (
                          <p className="text-sm text-gray-600 mt-1">
                            Code: {selectedOutcome.code}
                          </p>
                        )}
                      </div>
                    )}

                    <div className="form-control w-full">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Justification (Optional)
                      </label>
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        value={outcomeJustification}
                        onChange={e => setOutcomeJustification(e.target.value)}
                        rows={2}
                        placeholder="Why is this outcome appropriate?"
                      />
                    </div>

                    <div className="flex justify-end">
                      <Button
                        variant="syllabyteGreen"
                        onClick={handleOutcomeSubmit}
                        disabled={!selectedOutcome}
                      >
                        Submit Outcome Suggestion
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="p-4 bg-gray-100 rounded-md">
                  <p className="text-center text-gray-600">
                    No syllabi available for outcome suggestions.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default SuggestionCreator;
