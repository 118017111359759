import { LightBulbIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import {
  Breadcrumbs,
  generateBreadcrumb,
} from "../../../components/Breadcrumbs";
import { MetaTags } from "../../../components/layouts/MetaTags";
import { HorizontalTabs } from "../../../components/navigation/HorizontalTabs";
import { ProfileBadge } from "../../../components/profile/ProfileBadge";
import { GroupedSuggestionList } from "../../../components/suggestions/GroupedSuggestionLists";
import SuggestionCreator from "../../../components/suggestions/SuggestionCreator";
import { ContentCard } from "../../../features/content/card/ContentCard";
import ContentMetaDropdownPanel from "../../../features/content/ContentMetadataDropdownPanel";
import { GroupedContentTasksList } from "../../../features/content/tasks/GroupedContentTasksList";
import { Content } from "../../../types/Content";
import { ListItem } from "../../../types/platform/ListItem";
import { SharedItem } from "../../../types/platform/SharedItem";
import { Suggestion } from "../../../types/Suggestion";

export interface SharedContentViewerProperties {
  accessKey?: string;
  sharedItem: SharedItem;
}

export function SharedContentViewer(
  properties: SharedContentViewerProperties
): JSX.Element {
  const {
    ownerId,
    ownerEmail,
    ownerName,
    id,
    content: contentPackage,
    downloadUrl,
  } = properties.sharedItem;

  useEffect(() => {
    console.log("SharedContentViewer properties:", properties);
  }, [properties]);

  if (contentPackage == undefined) {
    throw new Error("Content package must be provided");
  }

  const titleTag = `Shared Upload: ${contentPackage.content.title}`;
  const descriptionTag = `Shared Content from ${ownerName}`;

  const breadcrumbs = generateBreadcrumb(
    generateBreadcrumb([], "Sharing & Privacy", "/sharing"),
    `Content: "${contentPackage?.content.title}" from ${ownerName}`,
    `/shared/content/${ownerId}/${id}`,
    `${ownerId}/${id}`
  );

  // Store content as local state (initialized from props)
  const [content] = useState<Content | undefined>(contentPackage.content);

  // Store suggestions as local state (initialized from props)
  const [suggestions, setSuggestions] = useState<Suggestion[]>(
    contentPackage.suggestions
  );

  // Store tasks locally (in case we need to update them later)
  const [tasks] = useState(contentPackage.tasks);

  const [selectedTab, setSelectedTab] = useState<string>("suggestions");

  // Derive tab options from current state (not props)
  const tabOptions: ListItem[] = [
    {
      label: `Suggestions (${suggestions.length})`,
      key: "suggestions",
      icon: LightBulbIcon,
      visible: true,
    },
    {
      label: `Tasks (${tasks.length})`,
      key: "tasks",
      icon: RectangleStackIcon,
      visible: true,
    },
  ];

  // Memoized handler for suggestion updates
  const handleSuggestionUpdate = useCallback(
    (updatedSuggestion: Suggestion) => {
      console.log("Updating suggestion:", updatedSuggestion);

      setSuggestions(prevSuggestions =>
        prevSuggestions.map(suggestion =>
          suggestion.id === updatedSuggestion.id
            ? updatedSuggestion
            : suggestion
        )
      );
    },
    []
  );

  /*
  // Memoized handler for adding new suggestions (if needed)
  const handleAddSuggestion = useCallback((newSuggestion: Suggestion) => {
    console.log("Adding new suggestion:", newSuggestion);
    setSuggestions(prevSuggestions => [...prevSuggestions, newSuggestion]);
  }, []);
*/

  return (
    <main>
      <MetaTags title={titleTag} description={descriptionTag} />

      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          basePath="/dashboard"
          breadcrumbs={breadcrumbs}
        ></Breadcrumbs>
      )}

      <div className="max-w-7xl w-full mx-auto xs:pt-8 sm:pt-12 xs:pb-4 sm:pb-8 xs:px-4 sm:px-8">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <ProfileBadge id={ownerId} name={ownerName} email={ownerEmail} />
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex"></div>
        </div>

        <hr className="mt-6 mb-6" />

        {content && (
          <>
            <ContentCard content={content}></ContentCard>

            <div className="my-4">
              <p className="mt-2 mb-1 font-medium">Extracted</p>
              <div className="border bg-gray-50 p-4 rounded-md shadow-sm">
                <p>{contentPackage.contentString}</p>
              </div>
            </div>

            {content.metadata && (
              <ContentMetaDropdownPanel
                metadata={contentPackage.content.metadata}
              />
            )}

            <div className="-mt-2 mb-4 ">
              <HorizontalTabs
                options={tabOptions}
                selectedKey={selectedTab}
                onTabChange={(tabKey: string) => setSelectedTab(tabKey)}
              />
            </div>

            {selectedTab === "suggestions" && (
              <>
                <div className="mb-4">
                  <SuggestionCreator
                    tasks={tasks}
                    buttonVariant="syllabyteGreen"
                  ></SuggestionCreator>
                </div>
                <GroupedSuggestionList
                  suggestions={suggestions}
                  onFeedbackSubmitted={handleSuggestionUpdate}
                  onEditSuggestion={handleSuggestionUpdate}
                />
              </>
            )}

            {selectedTab === "tasks" && (
              <GroupedContentTasksList tasks={tasks}></GroupedContentTasksList>
            )}
          </>
        )}
        <p>Download: {downloadUrl}</p>
      </div>
    </main>
  );
}
