import { DocumentIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { FileIcon } from "../../../components/FileIcon";
import { Content } from "../../../types/Content";

interface ContentCardThumbnailProps {
  content: Content;
  isVisible: boolean;
  isWebContent: boolean;
  isUploadComplete: boolean;
}

export function ContentCardThumbnail({
  content,
  isVisible,
  isWebContent,
  isUploadComplete,
}: ContentCardThumbnailProps): JSX.Element {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Reset state when content changes
  useEffect(() => {
    setImageLoaded(false);
    setImageError(false);
  }, [content.id]);

  // Determine whether a thumbnail should be shown
  const shouldShowThumbnail = () => {
    // Don't show thumbnails for web content
    if (isWebContent) {
      return false;
    }

    // Don't show if upload is incomplete
    if (!isUploadComplete) {
      return false;
    }

    // Certain file types should have thumbnails
    const thumbnailFileTypes = [
      "pdf",
      "jpg",
      "jpeg",
      "png",
      "gif",
      "mp4",
      "mov",
    ];
    const fileExt = content.fileExtension?.toLowerCase() || "";

    return thumbnailFileTypes.includes(fileExt);
  };

  // Attempt to load the thumbnail image only if visible and should show
  useEffect(() => {
    if (
      isVisible &&
      shouldShowThumbnail() &&
      content.id &&
      !imageLoaded &&
      !imageError
    ) {
      // Create a new image object to preload
      const img = new window.Image();
      img.onload = () => setImageLoaded(true);
      img.onerror = () => setImageError(true);
      img.src = `/api/content/thumbnails/${content.id}/0`;
    }
  }, [isVisible, content.id, imageLoaded, imageError]);

  // Render placeholder for non-visible or loading state
  if (!isVisible) {
    return (
      <div className="w-48 h-48 flex items-center justify-center bg-gray-200">
        <DocumentIcon className="h-12 w-12 text-gray-300" />
      </div>
    );
  }

  // Render web content icon
  if (isWebContent) {
    return (
      <div className="w-48 h-48 flex flex-col items-center justify-center">
        <GlobeAltIcon className="h-12 w-12 text-gray-300" />
        <p className="text-sm text-gray-500 mt-2">Web Content</p>
      </div>
    );
  }

  // Render thumbnail if available and loaded
  if (shouldShowThumbnail() && !imageError) {
    return (
      <div
        ref={containerRef}
        className="w-48 h-48 overflow-hidden flex items-center justify-center relative"
      >
        {/* Show loading skeleton while image loads */}
        {!imageLoaded && (
          <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
        )}

        {/* Only attempt to load if visible and content id exists */}
        {content.id && (
          <img
            ref={imageRef}
            src={`/api/content/thumbnails/${content.id}/0`}
            alt={content.title || "Content thumbnail"}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? "opacity-100" : "opacity-0"
            }`}
            loading="lazy"
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageError(true)}
          />
        )}
      </div>
    );
  }

  // Default: render file icon
  return (
    <div className="w-48 h-48 flex flex-col items-center justify-center">
      <FileIcon
        fileExtension={content.fileExtension || ""}
        className="h-12 w-12"
      />
      <p className="text-sm text-gray-500 mt-2">
        {isUploadComplete
          ? `${content.fileExtension || "File"}`
          : "Uploading..."}
      </p>
    </div>
  );
}
