import clsx from "clsx";
import { BloomsType } from "../../types/Outcome";

export interface BloomsBadgeProperties {
  bloomsType?: string;
  className?: string;
}

export function BloomsBadge(properties: BloomsBadgeProperties): JSX.Element {
  const { bloomsType, className } = properties;

  return (
    <span>
      {bloomsType !== undefined && bloomsType !== BloomsType.NotSet && (
        <span
          className={clsx(
            className,
            "whitespace-nowrap capitalize inline-block px-2.5 py-0.5 rounded-md text-sm font-medium bg-syllabyte-navy text-white",
          )}
        >
          {bloomsType}
        </span>
      )}
    </span>
  );
}
