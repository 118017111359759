import React, { ReactNode, useRef, useEffect } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariants } from "./fields/Button";

type ActionType = "submit" | "button";

interface ModalAction<T = void> {
  label: string;
  onClick: () => Promise<T> | T;
  variant?: ButtonVariants;
  type?: ActionType;
  disabled?: boolean;
  isLoading?: boolean;
}

interface ModalProperties<T = void> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  children: ReactNode;
  primaryAction?: ModalAction<T>;
  secondaryAction?: ModalAction;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
  disabled?: boolean;
  showCancel?: boolean;
  cancelText?: string;
}

export function Modal<T = void>({
  isOpen,
  onClose,
  title,
  description,
  children,
  primaryAction,
  secondaryAction,
  size = "md",
  disabled = false,
  showCancel = false,
  cancelText = "Cancel",
}: ModalProperties<T>): JSX.Element {
  const initialFocusReference = useRef(null);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !disabled) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose, disabled]);

  const sizeClasses = {
    xs: "xs:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-lg",
    lg: "sm:max-w-xl",
    xl: "sm:max-w-2xl",
    "2xl": "sm:max-w-3xl",
    "3xl": "sm:max-w-4xl",
    "4xl": "sm:max-w-5xl",
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={disabled ? () => {} : onClose}
        initialFocus={initialFocusReference}
      >
        <TransitionChild
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center xs:items-center xs:p-0">
            <TransitionChild
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95"
              enterTo="opacity-100 translate-y-0 xs:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 xs:scale-100"
              leaveTo="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95"
            >
              <DialogPanel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full ${sizeClasses[size]} xs:my-8 xs:p-6`}
              >
                <div className="absolute right-0 top-0 pr-4 pt-4 block">
                  <Button
                    onClick={onClose}
                    variant="round"
                    aria-label="Close"
                    disabled={disabled}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </div>
                <div className="xs:flex xs:items-start">
                  <div className="mt-3 text-center xs:mt-0 xs:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-xl xs:text-2xl font-semibold leading-6"
                    >
                      {title}
                    </DialogTitle>
                    {description && (
                      <p className="mt-2 xs:mt-4 text-sm xs:text-base">
                        {description}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4 xs:mt-5">{children}</div>
                <div className="mt-5 xs:mt-6 flex flex-col xs:flex-row-reverse gap-2 xs:gap-3">
                  {primaryAction && (
                    <Button
                      onClick={primaryAction.onClick}
                      variant={primaryAction.variant || "syllabyteBlue"}
                      type={primaryAction.type || "button"}
                      disabled={disabled || primaryAction.disabled}
                      className="w-full xs:w-auto"
                      isLoading={primaryAction.isLoading}
                    >
                      {primaryAction.label}
                    </Button>
                  )}
                  {showCancel && (
                    <Button
                      onClick={onClose}
                      variant="greyOutline"
                      type="button"
                      disabled={disabled}
                      className="xs:w-auto"
                    >
                      {cancelText || "Cancel"}
                    </Button>
                  )}
                  {secondaryAction && (
                    <Button
                      onClick={secondaryAction.onClick}
                      variant={secondaryAction.variant || "greyOutline"}
                      type={secondaryAction.type || "button"}
                      disabled={disabled || secondaryAction.disabled}
                      className={`w-full xs:w-auto ${
                        showCancel ? "xs:mr-auto" : ""
                      }`}
                    >
                      {secondaryAction.label}
                    </Button>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
