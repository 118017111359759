import React from "react";
import { DropdownPanel } from "../../components/fields/DropdownPanel";

interface ContentMetaDropdownPanelProperties {
  metadata: Record<string, string | number> | null | undefined;
}

const ContentMetaDropdownPanel: React.FC<
  ContentMetaDropdownPanelProperties
> = ({ metadata }) => {
  // Only render if metadata exists and has properties
  if (!metadata || Object.keys(metadata).length === 0) {
    return null;
  }

  return (
    <div className="mt-4 mb-6">
      <DropdownPanel
        itemId="metadata-panel"
        title={
          <div className="flex text-lg font-bold items-center">
            <span>Content Metadata</span>
          </div>
        }
        menuEnabled={false}
        defaultOpen={false}
        readingChildren={
          <div className="flex items-start">
            <ul className="list-disc list-inside text-sm space-y-1">
              {Object.entries(metadata).map(([key, value], index) => (
                <li key={index} className="text-gray-700">
                  <span className="font-medium">{key}:</span> {value}
                </li>
              ))}
            </ul>
          </div>
        }
      />
    </div>
  );
};

export default ContentMetaDropdownPanel;
