import clsx from "clsx";
import { ListItem } from "../../types/platform/ListItem";

interface HorizontalTabsProperties {
  options: ListItem[];
  selectedKey: string;
  onTabChange: (key: string) => void;
}

export const HorizontalTabs = (
  properties: HorizontalTabsProperties
): JSX.Element => {
  const { options, selectedKey, onTabChange } = properties;

  return (
    <div className="flex flex-col md:flex-row border-b xs:text-center sm:text-left">
      {options.map(
        ({ label, icon: HeroIcon, key, visible, disabled }) =>
          visible && (
            <a
              key={key}
              onClick={() => !disabled && onTabChange(key)}
              className={clsx(
                "group border-b-4 px-3 py-4 flex items-center font-medium transition-colors duration-150",
                disabled
                  ? "cursor-not-allowed text-gray-400"
                  : selectedKey === key
                    ? ["cursor-pointer", "border-syllabyte-blue"]
                    : [
                        "cursor-pointer",
                        "border-transparent",
                        "hover:bg-gray-50",
                      ]
              )}
            >
              {HeroIcon && (
                <HeroIcon
                  className={clsx(
                    "h-5 w-5 inline-block",
                    disabled
                      ? "text-gray-400"
                      : "group-hover:text-syllabyte-blue"
                  )}
                />
              )}
              <span className="ml-2 capitalize">{label}</span>
            </a>
          )
      )}
    </div>
  );
};
