import {
  AcademicCapIcon,
  ArrowPathIcon,
  BookOpenIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { Badge } from "../../../components/badges/Badge";
import { IdDisplayHelper } from "../../../components/IdDisplayHelper";
import {
  ContentTask,
  getTaskStateLabel,
  getTaskTypeLabel,
} from "../../../types/ContentTask";

interface ContentTaskCardProperties {
  task: ContentTask;
  onSelect?: () => void;
}

export function ContentTaskCard({
  task,
  onSelect,
}: ContentTaskCardProperties): JSX.Element {
  // Get configuration properties
  const configProperties = useMemo(() => {
    const properties = [];

    if (task.outputLanguage) {
      properties.push({
        label: "Output Language",
        value: task.outputLanguage,
        icon: GlobeAltIcon,
      });
    }

    if (task.confidence !== undefined) {
      properties.push({
        label: "Confidence",
        value: `${(task.confidence * 100).toFixed(0)}%`,
        icon: LightBulbIcon,
      });
    }

    if (task.clearExistingSuggestions !== undefined) {
      properties.push({
        label: "Clear Existing Suggestions",
        value: task.clearExistingSuggestions ? "Yes" : "No",
        icon: ArrowPathIcon,
      });
    }

    if (task.condenseSlices !== undefined) {
      properties.push({
        label: "Condense Slices",
        value: task.condenseSlices ? "Yes" : "No",
        icon: InformationCircleIcon,
      });
    }

    if (task.enforceSyllabiCoverage !== undefined) {
      properties.push({
        label: "Enforce Syllabi Coverage",
        value: task.enforceSyllabiCoverage ? "Yes" : "No",
        icon: BookOpenIcon,
      });
    }

    if (task.size !== undefined) {
      properties.push({
        label: "Size",
        value: task.size,
        icon: InformationCircleIcon,
      });
    }

    if (task.minWidth !== undefined || task.minHeight !== undefined) {
      properties.push({
        label: "Dimensions",
        value: `${task.minWidth || "auto"} × ${task.minHeight || "auto"}`,
        icon: InformationCircleIcon,
      });
    }

    return properties;
  }, [task]);

  return (
    <div className="bg-gray-50 shadow border rounded-lg p-4 space-y-4">
      {/* Header Section with Key Information */}
      <div className="border-b border-gray-200 pb-2">
        <div className="flex items-center space-x-2 ">
          <h2 className="block text-xl font-medium">
            {getTaskTypeLabel(task.task || "")} Task
          </h2>
          <Badge
            text={getTaskStateLabel(task.state || "")}
            foregroundColor="white"
            backgroundColor="gray"
          />
        </div>

        <div className="mt-2">
          <p>{task.message}</p>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        {task.id && (
          <IdDisplayHelper
            id={task.id}
            truncateLength={12}
            showPrefix={true}
            label="Task ID"
          />
        )}

        {task.contentId && (
          <IdDisplayHelper
            id={task.contentId}
            truncateLength={12}
            showPrefix={true}
            label="Content ID"
          />
        )}
        {task.sliceId && task.sliceId !== task.contentId && (
          <IdDisplayHelper
            id={task.sliceId}
            truncateLength={12}
            showPrefix={true}
            label="Slice ID"
          />
        )}
      </div>

      {/* Syllabi Details */}
      {task.syllabi && task.syllabi.length > 0 && (
        <div className="pt-2 space-y-4">
          {task.syllabi.map((syllabus, index) => (
            <div key={index} className="">
              <h5 className="font-medium text-gray-900 mb-2">
                {syllabus.title || "Unnamed Syllabus"}
              </h5>

              <div className="space-y-2">
                {syllabus.ownerId && (
                  <IdDisplayHelper
                    id={syllabus.ownerId}
                    truncateLength={8}
                    showPrefix={true}
                    label="Owner ID"
                  />
                )}

                {syllabus.id && (
                  <IdDisplayHelper
                    id={syllabus.id}
                    truncateLength={12}
                    showPrefix={true}
                    label="Syllabus ID"
                  />
                )}

                {syllabus.subject && (
                  <div className="flex items-center gap-1.5 text-sm">
                    <AcademicCapIcon className="h-4 w-4 text-gray-500" />
                    <span className="text-gray-500 font-medium">Subject:</span>
                    <span>{syllabus.subject}</span>
                  </div>
                )}

                {syllabus.description && (
                  <div className="text-sm">
                    <details>
                      <summary className="cursor-pointer text-gray-700 font-medium">
                        Description
                      </summary>
                      <p className="mt-2 text-gray-700 p-2 border border-gray-200 rounded">
                        {syllabus.description}
                      </p>
                    </details>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Custom Lists Details */}
      {task.customLists && task.customLists.length > 0 && (
        <div className="pt-2 space-y-4">
          {task.customLists.map((list, index) => (
            <div key={index} className="">
              <h5 className="font-medium text-gray-900 mb-2">
                {list.title || "Unnamed List"}
              </h5>

              <div className="space-y-2">
                {list.id && (
                  <IdDisplayHelper
                    id={list.id}
                    truncateLength={12}
                    showPrefix={true}
                    label="List ID"
                  />
                )}

                {list.ownerId && (
                  <IdDisplayHelper
                    id={list.ownerId}
                    truncateLength={8}
                    showPrefix={true}
                    label="List OwnerId"
                  />
                )}

                {list.items && list.items.length > 0 && (
                  <div className="text-sm">
                    <details>
                      <summary className="cursor-pointer text-gray-700 font-medium">
                        Items ({list.items.length})
                      </summary>
                      <ul className="mt-2 space-y-1 p-2 border border-gray-200 rounded list-disc pl-5">
                        {list.items.map((item, idx) => (
                          <li key={idx} className="text-gray-700">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </details>
                  </div>
                )}

                {list.description && (
                  <div className="text-sm">
                    <details>
                      <summary className="cursor-pointer text-gray-700 font-medium">
                        Description
                      </summary>
                      <p className="mt-2 text-gray-700 p-2 border border-gray-200 rounded">
                        {list.description}
                      </p>
                    </details>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Classification Targets */}
      {task.classificationTargets && task.classificationTargets.length > 0 && (
        <div className="border-b border-gray-200">
          <h4 className="text-sm font-semibold text-gray-700 mb-3">
            Classification Targets
          </h4>
          <ul className="list-disc pl-5 space-y-1">
            {task.classificationTargets.map((target, index) => (
              <li key={index} className="text-sm text-gray-700">
                {target}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Configuration Properties */}
      {configProperties.length > 0 && (
        <div className="border-t pt-4 pb-2 border-gray-200">
          <h4 className="text-sm font-semibold text-gray-700 mb-4">
            Configuration
          </h4>
          <div className="space-y-2">
            {configProperties.map((prop, index) => {
              const PropertyIcon = prop.icon;
              return (
                <div key={index} className="flex items-center gap-2">
                  <PropertyIcon className="h-5 w-5 text-gray-500" />
                  <span className="text-sm font-medium text-gray-700">
                    {prop.label}:
                  </span>
                  <span className="text-sm text-gray-900">
                    {prop.value?.toString()}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Outcomes */}
      {task.outcomes && task.outcomes.length > 0 && (
        <div className="p-5">
          <h4 className="text-sm font-semibold text-gray-700 mb-3">Outcomes</h4>
          <div className="space-y-4">
            {task.outcomes.map((outcome, index) => (
              <div key={index} className="">
                <h5 className="font-medium text-gray-900 mb-2">
                  {outcome.description || "Unnamed Outcome"}
                </h5>

                <div className="space-y-2">
                  {outcome.id && (
                    <div className="flex items-center gap-1.5 text-sm">
                      <span className="text-gray-500 font-medium">ID:</span>
                      <IdDisplayHelper
                        id={outcome.id}
                        truncateLength={12}
                        showPrefix={true}
                      />
                    </div>
                  )}

                  {outcome.description && (
                    <div className="text-sm">
                      <span className="text-gray-500 font-medium">
                        Description:
                      </span>
                      <p className="mt-1 text-gray-700">
                        {outcome.description}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Progress Information */}
      {task.progressPercent !== undefined &&
        task.progressPercent !== 0 &&
        task.progressPercent !== 100 && (
          <div className="pt-4 border-t border-gray-200">
            <div className="flex items-center justify-between mb-2">
              <h4 className="text-sm font-semibold text-gray-700">Progress</h4>
              <span className="text-sm text-gray-600">
                {task.progressPercent}%{" "}
                {task.step && task.max
                  ? ` (Step ${task.step}/${task.max})`
                  : ""}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="h-2.5 rounded-full bg-gray-500"
                style={{ width: `${task.progressPercent}%` }}
              ></div>
            </div>
          </div>
        )}
    </div>
  );
}
