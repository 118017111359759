import { OutcomeCard } from "../../features/outcomes/OutcomeCard";
import { getTaskTypeLabel } from "../../types/ContentTask";
import { Suggestion } from "../../types/Suggestion";
import { IdDisplayHelper } from "../IdDisplayHelper";
import TrafficLight from "../TrafficLight";
import { SuggestionFeedbackForm } from "./SuggestionFeedbackForm";
import { SuggestionFeedbackList } from "./SuggestionFeedbackList";

export interface SuggestionCardProperties {
  suggestion: Suggestion;
  onAddSuggestion?: (suggestion: Suggestion) => void;
  onEditSuggestion?: (suggestion: Suggestion) => void;
  onDeleteSuggestion?: (suggestion: Suggestion) => void;
  onFeedbackSubmitted?: (suggestion: Suggestion) => void;
  hideConfidence?: boolean;
  hideJustification?: boolean;
  hideFeedback?: boolean;
  focusMode?: boolean;
}

export function SuggestionCard(
  properties: SuggestionCardProperties
): JSX.Element {
  const {
    suggestion,
    onAddSuggestion,
    onEditSuggestion,
    onDeleteSuggestion,
    onFeedbackSubmitted,
    hideConfidence = false,
    hideJustification = false,
    hideFeedback = false,
    focusMode = false,
  } = properties;

  const hasFeedback = suggestion.feedback && suggestion.feedback.length > 0;

  const handleFeedbackSubmitted = (updatedSuggestion: Suggestion) => {
    if (onFeedbackSubmitted) {
      onFeedbackSubmitted(updatedSuggestion);
    }
  };

  return (
    <div className="bg-gray-50 shadow border rounded-lg p-4 space-y-6">
      {/* Header with type badge if available */}
      {suggestion.suggestionType && (
        <div className="flex items-center space-x-2">
          <h2 className="block text-xl font-medium">
            {getTaskTypeLabel(suggestion.suggestionType)} Suggestion
          </h2>

          {/* Confidence indicator */}
          {!hideConfidence && suggestion.confidence !== undefined && (
            <div className="flex items-center">
              <div className="w-6 mr-1">
                <TrafficLight value={suggestion.confidence} />
              </div>
              <p className="pl-2">
                <span className="text-lg font-medium">
                  {suggestion.confidence.toFixed(2)}
                </span>
              </p>
            </div>
          )}

          {suggestion.id && (
            <IdDisplayHelper id={suggestion.id} label="Suggestion Id" />
          )}
        </div>
      )}

      {/* Original text if available */}
      {suggestion.originalText && (
        <div className="flex space-x-2">
          <p className="font-medium">Current:</p>
          <p className="text-gray-700">{suggestion.originalText}</p>
        </div>
      )}

      {/* Suggested text if available */}
      {suggestion.suggested && (
        <div className="flex space-x-2">
          <p className="font-medium">Suggested:</p>
          <p className="text-green-600">{suggestion.suggested}</p>
        </div>
      )}

      {/* Outcome if available */}
      {suggestion.outcome && (
        <div className="flex flex-col space-y-2">
          <p className="font-medium">Outcome:</p>
          <div className="ml-2">
            <OutcomeCard
              hideBottomBorder={true}
              focusMode={focusMode}
              outcome={suggestion.outcome}
            />
          </div>
        </div>
      )}

      {/* Justification if available and not hidden */}
      {suggestion.justification && !hideJustification && (
        <div className="">
          <p className="font-medium">Justification:</p>
          <p className="">{suggestion.justification}</p>
        </div>
      )}

      {/* Display existing feedback if available and not hidden */}
      {!hideFeedback && hasFeedback && (
        <SuggestionFeedbackList feedback={suggestion.feedback} />
      )}

      {/* Feedback form */}
      {!hideFeedback && (
        <SuggestionFeedbackForm
          suggestion={suggestion}
          onFeedbackSubmitted={handleFeedbackSubmitted}
        />
      )}
    </div>
  );
}
