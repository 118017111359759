import { useState } from "react";

/**
 * Interface for IdDisplayHelper component properties
 * @property {string} id - The full ID string to display
 * @property {number} truncateLength - Number of characters to show from the end of the ID (default: 8)
 * @property {boolean} showPrefix - Whether to show characters from the beginning of the ID (default: false)
 * @property {string} label - Optional label to display alongside the truncated ID (e.g., "Slice ID", "User ID")
 */
export interface IdDisplayHelperProperties {
  id: string;
  truncateLength?: number;
  showPrefix?: boolean;
  label?: string;
}

/**
 * Component that displays a truncated version of an ID string
 * and expands to show the full ID on hover or click
 */
export const IdDisplayHelper = (
  properties: IdDisplayHelperProperties
): JSX.Element | null => {
  // Destructure and set default values for properties
  const { id, truncateLength = 8, showPrefix = false, label } = properties;

  // Track whether the ID is currently expanded or truncated
  const [expanded, setExpanded] = useState(false);

  // Track clipboard copy status
  const [copied, setCopied] = useState(false);

  // Return null for empty strings
  if (!id) {
    return null;
  }

  /**
   * Returns a truncated version of the ID
   * If showPrefix is true, shows beginning and end portions
   * Otherwise shows only the end portion
   */
  const getTruncatedId = (): string => {
    // If ID is already short enough, return the whole thing
    if (id.length <= truncateLength) {
      return id;
    }

    // Choose truncation style based on showPrefix setting
    return showPrefix
      ? `${id.substring(0, 4)}...${id.substring(id.length - truncateLength)}` // Show prefix + suffix
      : `...${id.substring(id.length - truncateLength)}`; // Show only suffix
  };

  /**
   * Copy ID to clipboard when expanded and clicked
   */
  const handleClick = (): void => {
    if (expanded) {
      // Copy to clipboard
      navigator.clipboard
        .writeText(id)
        .then(() => {
          setCopied(true);
          // Reset copied state after 2 seconds
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Just expand if not already expanded
      setExpanded(true);
    }
  };

  return (
    <div
      className="inline-block cursor-pointer font-mono text-sm transition-all duration-200"
      onClick={handleClick}
      onMouseEnter={() => setExpanded(true)} // Expand on hover
      onMouseLeave={() => setExpanded(false)} // Collapse when mouse leaves
    >
      <div className="bg-gray-100 rounded px-2 py-1 hover:bg-gray-200 relative">
        {expanded ? (
          <span className="break-all">
            {copied ? (
              <span className="text-green-600 font-medium">Copied!</span>
            ) : (
              id
            )}
          </span> // Show full ID when expanded or "Copied!" notification
        ) : (
          <span>
            {label && <span className="font-sans mr-1">{label}:</span>}
            <span>{getTruncatedId()}</span>
          </span> // Show label alongside truncated ID when collapsed
        )}
      </div>
    </div>
  );
};
