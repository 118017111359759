import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Search } from "../../components/fields/Search";
import { Loading } from "../../components/Loading";
import { Outcome } from "../../types/Outcome";
import { Syllabus } from "../../types/Syllabus";
import { getOutcomes } from "../../util/ApiIntegration";
import { OutcomesList } from "./OutcomesList";

export interface OutcomesPickerProperties {
  syllabus?: Syllabus;
  onOutcomesChanged?: (outcomes: Outcome[]) => void;
  onSelectOutcome?: (outcome: Outcome) => void;
  onEditOutcome?: (outcome: Outcome) => void;
  onDeleteOutcome?: (outcome: Outcome) => void;
  tall?: boolean;
}

export const OutcomesPicker = (
  properties: OutcomesPickerProperties
): JSX.Element => {
  const {
    syllabus,
    onOutcomesChanged,
    onSelectOutcome,
    onEditOutcome,
    onDeleteOutcome,
    tall,
  } = properties;

  // searching and filtering outcomes
  const [filteredOutcomes, setFilteredOutcomes] = useState<Outcome[]>();

  // load the outcomes in a syllabi for a provider
  const {
    data: outcomesForSyllabus,
    isFetching: isFetchingOutcomesForSyllabus,
  } = useQuery({
    queryKey: ["outcomesForSyllabus", `${syllabus?.ownerId}-${syllabus?.id}`],
    queryFn: () => getOutcomes(syllabus?.ownerId, syllabus?.id),
    refetchOnWindowFocus: false,
    enabled:
      syllabus && syllabus?.ownerId !== undefined && syllabus?.id !== undefined,
  });

  useEffect(() => {
    if (onOutcomesChanged && outcomesForSyllabus) {
      onOutcomesChanged(outcomesForSyllabus);
    }

    setFilteredOutcomes(outcomesForSyllabus);
  }, [outcomesForSyllabus]);

  const handleSearch = (searchText: string) => {
    if (!outcomesForSyllabus) {
      return;
    }

    const text = searchText.toLowerCase();

    const result = outcomesForSyllabus.filter(
      data =>
        data.description?.toLowerCase().includes(text) ||
        data.code?.toLowerCase().includes(text)
    );

    setFilteredOutcomes(result);
  };

  return (
    <>
      {/* loading provider options */}
      {isFetchingOutcomesForSyllabus && !outcomesForSyllabus && (
        <Loading></Loading>
      )}

      {outcomesForSyllabus && outcomesForSyllabus.length > 0 && (
        <Search
          onSearch={handleSearch}
          onClear={() => setFilteredOutcomes(outcomesForSyllabus)}
          placeHolder="Compare and contrast..."
          searchButtonRequired={false}
        />
      )}

      {filteredOutcomes && filteredOutcomes.length > 0 && (
        <div className="mt-4">
          <p className="font-medium mb-1">Outcomes</p>
          <OutcomesList
            tall={tall}
            outcomes={filteredOutcomes}
            onAddOutcome={onSelectOutcome}
            onEditOutcome={onEditOutcome}
            onRemoveOutcome={onDeleteOutcome}
          ></OutcomesList>
        </div>
      )}
    </>
  );
};
