import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { ListItem } from "../../types/platform/ListItem";

interface DropdownPanelProperties {
  itemId: string;
  title?: JSX.Element | string;
  itemIndex?: number;
  itemsLength?: number;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  canEdit?: boolean;
  remove?: () => void;
  move?: (from: number, to: number) => void;
  duplicate?: () => void;
  editingChildren?: React.ReactNode;
  readingChildren?: React.ReactNode;
  menuEnabled: boolean;
  defaultOpen: boolean;
  onOpen?: (open: boolean) => void;
  customMenuItems?: ListItem[];
  customButtons?: ListItem[];
}

export const DropdownPanel = (
  properties: DropdownPanelProperties,
): JSX.Element => {
  const {
    title,
    itemIndex,
    itemsLength,
    isEditing,
    setIsEditing,
    canEdit,
    remove,
    move,
    duplicate,
    editingChildren,
    readingChildren,
    menuEnabled,
    defaultOpen,
    onOpen,
    customMenuItems = [],
    customButtons = [],
  } = properties;

  const renderMenuItem = (item: ListItem) => {
    if (item.visible === false) return;

    const Icon = item.icon;

    return (
      <MenuItem as="div" key={item.key}>
        {({ active }) => (
          <div
            onClick={() => {
              if (!item.disabled && item.action) {
                item.action();
              }
            }}
            className={`group cursor-pointer h-10 px-3 flex items-center text-sm font-medium
              ${item.disabled ? "opacity-50 cursor-not-allowed" : active ? "bg-gray-50 text-syllabyte-blue" : ""}
              ${item.customBackground || ""}`}
          >
            {Icon && (
              <Icon
                className="flex-shrink-0 mr-3 h-5 w-5 group-hover:text-syllabyte-blue"
                aria-hidden="true"
              />
            )}
            <span className="flex-grow">{item.label}</span>
            {item.description && (
              <span className="ml-2 text-gray-500 text-xs">
                {item.description}
              </span>
            )}
            {item.new && (
              <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                New
              </span>
            )}
          </div>
        )}
      </MenuItem>
    );
  };

  const renderCustomButton = (item: ListItem) => {
    if (item.visible === false || !item.icon) return;
    const Icon = item.icon;

    return (
      <div>
        <button
          key={item.key}
          onClick={() => {
            if (!item.disabled && item.action) {
              item.action();
            }
          }}
          title={item.label}
          disabled={item.disabled}
          className={`mr-2 p-2 border border-gray-300 bg-white shadow-sm hover:bg-gray-50 rounded-full
          ${item.disabled ? "opacity-50 cursor-not-allowed" : ""}
          ${item.customBackground || ""}`}
          type="button"
        >
          <span className="sr-only">{item.label}</span>
          <Icon width={15} height={15} />
        </button>
      </div>
    );
  };

  return (
    <div key={`component-${itemIndex}`}>
      <Disclosure
        as="div"
        defaultOpen={defaultOpen}
        key={`dropdownPanelComponent-${itemIndex}`}
      >
        {({ open }) => (
          <>
            <dt className="text-lg flex border-b pb-2">
              <DisclosureButton
                onClick={() => {
                  if (onOpen) {
                    onOpen(!open);
                  }
                }}
                className="text-left w-full flex justify-between items-start"
              >
                <span className="font-bold mt-1">{title || "Title"}</span>

                <span className="mr-2 h-7 flex items-center hover:text-syllabyte-blue">
                  <span className="text-sm mt-1">
                    {open ? (
                      <div title="Minimise" className="m-2 mr-2">
                        <ChevronUpIcon width={15} height={15} />
                      </div>
                    ) : (
                      <div title="Expand" className="m-2 mr-2">
                        <ChevronDownIcon width={15} height={15} />
                      </div>
                    )}
                  </span>
                </span>
              </DisclosureButton>
              <div className="flex">
                {customButtons.map(button => renderCustomButton(button))}
                {menuEnabled && (
                  <Menu as="div" className="relative">
                    <div>
                      <MenuButton
                        title="Open Menu"
                        className="mr-2 p-2 border border-gray-300 bg-white shadow-sm hover:bg-gray-50 rounded-full"
                        type="button"
                      >
                        <span className="sr-only">Open Menu</span>
                        <EllipsisVerticalIcon width={15} height={15} />
                      </MenuButton>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="origin-top-right z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        {/* Default Items Section */}
                        <div className="py-1">
                          {canEdit && setIsEditing && (
                            <MenuItem>
                              <div
                                onClick={() => setIsEditing(!isEditing)}
                                className="group cursor-pointer border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent hover:bg-gray-50 hover:text-syllabyte-blue"
                              >
                                <PencilIcon
                                  className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-syllabyte-blue"
                                  aria-hidden="true"
                                />
                                {isEditing ? "Stop Editing" : "Edit"}
                              </div>
                            </MenuItem>
                          )}

                          {duplicate && (
                            <MenuItem>
                              <div
                                onClick={duplicate}
                                className="group cursor-pointer border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent hover:bg-gray-50 hover:text-syllabyte-blue"
                              >
                                <DocumentDuplicateIcon
                                  className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-syllabyte-blue"
                                  aria-hidden="true"
                                />
                                Duplicate
                              </div>
                            </MenuItem>
                          )}

                          {move &&
                            itemIndex !== undefined &&
                            itemsLength !== undefined && (
                              <>
                                {itemIndex > 0 && (
                                  <MenuItem>
                                    <div
                                      onClick={() =>
                                        move(itemIndex, itemIndex - 1)
                                      }
                                      className="group cursor-pointer border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent hover:bg-gray-50 hover:text-syllabyte-blue"
                                    >
                                      <ChevronUpIcon
                                        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-syllabyte-blue"
                                        aria-hidden="true"
                                      />
                                      Move Up
                                    </div>
                                  </MenuItem>
                                )}
                                {itemIndex < itemsLength - 1 && (
                                  <MenuItem>
                                    <div
                                      onClick={() =>
                                        move(itemIndex, itemIndex + 1)
                                      }
                                      className="group cursor-pointer border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent hover:bg-gray-50 hover:text-syllabyte-blue"
                                    >
                                      <ChevronDownIcon
                                        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-syllabyte-blue"
                                        aria-hidden="true"
                                      />
                                      Move Down
                                    </div>
                                  </MenuItem>
                                )}
                              </>
                            )}
                        </div>

                        {/* Custom Items Section */}
                        {customMenuItems.length > 0 && (
                          <div className="py-1">
                            {customMenuItems.map(element =>
                              renderMenuItem(element),
                            )}
                          </div>
                        )}

                        {/* Remove Item Section */}
                        {remove && (
                          <div className="py-1">
                            <MenuItem>
                              <div
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete?",
                                    )
                                  ) {
                                    remove();
                                  }
                                }}
                                className="group cursor-pointer border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent hover:bg-gray-50 hover:text-syllabyte-blue"
                              >
                                <TrashIcon
                                  className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-syllabyte-blue"
                                  aria-hidden="true"
                                />
                                Remove
                              </div>
                            </MenuItem>
                          </div>
                        )}
                      </MenuItems>
                    </Transition>
                  </Menu>
                )}
              </div>
            </dt>

            <DisclosurePanel as="dd" className="whitespace-pre-line pt-4">
              {isEditing && <div>{editingChildren}</div>}
              {!isEditing && <div>{readingChildren}</div>}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
