import clsx from "clsx";
import imgVoid from "../assets/images/undraw_void_-3-ggu.svg";

export interface NotFoundNothingHereProperties {
  text: string;
  hasBorder?: boolean;
}

export const NotFoundNothingHere = (
  properties: NotFoundNothingHereProperties,
): JSX.Element => {
  const { text, hasBorder } = properties;

  return (
    <div className={clsx("pt-6", hasBorder ? "border-t" : "")}>
      <p className="text-center mb-6">{text}</p>
      <img
        loading="lazy"
        className="w-40 mx-auto opacity-50"
        src={imgVoid}
        title="Nothing here..."
        alt="Nothing here..."
      />
    </div>
  );
};
