import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios, { AxiosError, HttpStatusCode } from "axios";
import { MSALInstance } from "../../App";
import { loginRequest } from "./MsalConfig";

export const syllabyteAxios = axios.create({
  baseURL: process.env.REACT_APP_SYLLABYTE_API_BASE,
  paramsSerializer: {
    indexes: true,
  },
});

// New validation error structure matching the backend
export interface ValidationError {
  code: string;
  message: string;
  attemptedValue?: string;
}

// The structure for grouped validation errors by field
export interface ValidationErrorDetails {
  fieldErrors: Record<string, ValidationError[]>;
  totalErrors: number;
}

// Base error response from API
export interface ApiErrorResponse {
  message: string;
  details?: any;
}

// Extended ApiError class with typed details
export class ApiError extends Error {
  public readonly statusCode: number;
  public readonly errorResponse?: ApiErrorResponse;

  // Helper methods to access specific error types
  public get validationErrors(): ValidationErrorDetails | undefined {
    return this.errorResponse?.details as ValidationErrorDetails;
  }

  public get fieldErrors(): Record<string, ValidationError[]> | undefined {
    return this.validationErrors?.fieldErrors;
  }

  constructor(
    statusCode: number,
    message: string,
    errorResponse?: ApiErrorResponse
  ) {
    super(message);
    this.name = "ApiError";
    this.statusCode = statusCode;
    this.errorResponse = errorResponse;
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  // Helper method to get errors for a specific form field (with camelCase conversion)
  public getFieldErrors(fieldName: string): ValidationError[] | undefined {
    if (!this.fieldErrors) return undefined;

    // Try direct match
    if (this.fieldErrors[fieldName]) {
      return this.fieldErrors[fieldName];
    }

    // Try with Pascal case conversion (Outcome.Description → outcome.description)
    const lowerCaseFieldName = fieldName.toLowerCase();

    // Find case-insensitive match
    const matchingKey = Object.keys(this.fieldErrors).find(
      key => key.toLowerCase() === lowerCaseFieldName
    );

    return matchingKey ? this.fieldErrors[matchingKey] : undefined;
  }

  // Helper to extract the first error message for a field (useful for Formik)
  public getFieldErrorMessage(fieldName: string): string | undefined {
    const errors = this.getFieldErrors(fieldName);
    return errors && errors.length > 0 ? errors[0].message : undefined;
  }

  // Helper to convert validation errors to a format Formik can directly use
  public toFormikErrors(): Record<string, string> {
    if (!this.fieldErrors) return {};

    const formikErrors: Record<string, string> = {};

    Object.entries(this.fieldErrors).forEach(([key, errors]) => {
      if (errors && errors.length > 0) {
        // Convert property name from PascalCase or dot notation to camelCase
        // E.g. "Outcome.Description" → "description"
        const parts = key.split(".");
        const lastPart = parts[parts.length - 1];
        const fieldName = lastPart.charAt(0).toLowerCase() + lastPart.slice(1);

        formikErrors[fieldName] = errors[0].message;
      }
    });

    return formikErrors;
  }
}

// Track MSAL initialization status
let msalInitialized = false;
let initializationPromise: Promise<void> | null = null;

// Function to ensure MSAL is initialized
const ensureMsalInitialized = async () => {
  if (msalInitialized) {
    return;
  }

  if (!initializationPromise) {
    initializationPromise = MSALInstance.initialize()
      .then(() => {
        msalInitialized = true;
        console.log("MSAL successfully initialized");
      })
      .catch(error => {
        console.error("MSAL initialization failed:", error);
        throw error;
      });
  }

  return initializationPromise;
};

syllabyteAxios.interceptors.request.use(
  async config => {
    try {
      // Ensure MSAL is initialized before proceeding
      await ensureMsalInitialized();

      const accounts = MSALInstance.getAllAccounts();
      if (!accounts || accounts.length === 0) {
        // no accounts in play so just return the config;
        return config;
      }

      try {
        // Use await directly rather than mixing with .then()
        const tokenResponse = await MSALInstance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // Set the token in the headers
        config.headers.Authorization = "Bearer " + tokenResponse.accessToken;
        return config;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.log("Interaction required for token acquisition");
          // Store current URL for after login
          if (window.location.pathname !== "/login") {
            localStorage.setItem("lastUrl", window.location.href);
            // Redirect to login
            await MSALInstance.loginRedirect(loginRequest);
          }
        }
        // Let the request proceed without a token if there's an error
        console.error("Token acquisition error:", error);
        return config;
      }
    } catch (initError) {
      console.error("Error ensuring MSAL initialization:", initError);
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

// Define expected API error response types
interface ApiResponseWithError {
  error?: {
    message: string;
    details?: any;
  };
  statusCode?: HttpStatusCode;
}

syllabyteAxios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response) {
      const { status } = error.response;
      // Type assertion to safely access error response data
      const data = error.response.data as ApiResponseWithError;

      switch (status) {
        case 401: {
          // Don't immediately log out - check initialization first
          if (msalInitialized) {
            if (window.location.pathname !== "/login") {
              localStorage.setItem("lastUrl", window.location.href);
              console.log("Unauthenticated. Logging Out", error);
              MSALInstance.logoutRedirect();
            }
          } else {
            console.log(
              "Authentication failed, but MSAL not initialized yet. Not logging out."
            );
          }
          break;
        }
        case 403: {
          window.location.href = "/pricing";
          break;
        }
        case 429: {
          const event = new CustomEvent("tooManyRequests", {
            detail: { message: "Too Many Requests" },
          });
          window.dispatchEvent(event);
          break;
        }
        default: {
          // Handle the new error format
          if (data?.error) {
            return Promise.reject(
              new ApiError(
                status,
                data.error.message || "An error occurred",
                data.error
              )
            );
          }
        }
      }

      // Generic error if no specific handling
      return Promise.reject(
        new ApiError(status, error.message || "An error occurred")
      );
    }
    return Promise.reject(new ApiError(500, "An unexpected error occurred"));
  }
);

export interface TooManyRequestsResponseEvent {
  message?: string;
}
