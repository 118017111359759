export const BOOKING_FORM_URL =
  "https://outlook.office365.com/book/Syllabyteai1@syllabyte.ai/";

export const SYLLABYTE_LOGO_DARK_URL =
  "https://assets.syllabyte.ai/images/syllabyte-horizontal-dark.svg";

export const SYLLABYTE_LOGO_LIGHT_URL =
  "https://assets.syllabyte.ai/images/syllabyte-horizontal-light.svg";

export const SYLLABYTE_LOGO_SQUARE =
  "https://assets.syllabyte.ai/images/syllabyte-ai-logo.png";

export const PARTNER_LOGO_VHL =
  "https://assets.syllabyte.ai/images/partner-logos/vista-higher-learning-logo.png";

export const PARTNER_LOGO_COPYRIGHTAGENCYAUSTRALIA =
  "https://assets.syllabyte.ai/images/partner-logos/copyright-agency-australia-logo.png";

export const PARTNER_LOGO_EDX =
  "https://assets.syllabyte.ai/images/partner-logos/edx-logo.png";

export const PARTNER_LOGO_TEACHOLOGYAI =
  "https://assets.syllabyte.ai/images/partner-logos/teachology-horizontal-logo.png";

// File type constants
export const ACCEPTED_MEDIA_EXTENSIONS = [
  // Audio
  ".mp3",
  ".wav",
  ".ogg",
  ".flac",
  ".aac",
  ".wma",
  // Video
  ".mp4",
  ".avi",
  ".mov",
  ".wmv",
  ".flv",
  ".mkv",
  ".webm",
  // Images
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".svg",
] as const;

export const ACCEPTED_DOCUMENT_EXTENSIONS = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".txt",
  ".rtf",
  ".eps",
  ".json",
  ".csv",
  ".xml",
  ".html",
] as const;

export const ALL_ACCEPTED_EXTENSIONS = [
  ...ACCEPTED_MEDIA_EXTENSIONS,
  ...ACCEPTED_DOCUMENT_EXTENSIONS,
] as const;

// Convert extensions array to accept string
export const ACCEPT_STRING = ALL_ACCEPTED_EXTENSIONS.join(",");
