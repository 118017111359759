import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { Button } from "../../components/fields/Button";
import { TextInputField } from "../../components/fields/TextInputField";
import { Suggestion, SuggestionFeedback } from "../../types/Suggestion";
import { putSuggestionFeedback } from "../../util/ApiIntegration";
import NumberPickerField from "../fields/NumberPickerField";

interface SuggestionFeedbackFormProps {
  suggestion: Suggestion;
  onFeedbackSubmitted?: (suggestion: Suggestion) => void;
}

interface FeedbackFormValues {
  message: string;
  feedbackType: "positive" | "neutral" | "negative" | "";
  confidence?: number;
}

const feedbackSchema = Yup.object().shape({
  feedbackType: Yup.string()
    .oneOf(["positive", "neutral", "negative"], "Please select feedback type")
    .required("Please select feedback type"),
});

export const SuggestionFeedbackForm: React.FC<SuggestionFeedbackFormProps> = ({
  suggestion,
  onFeedbackSubmitted,
}) => {
  // Use suggestion ID as the form key, with a fallback for suggestions without IDs
  const formKey = suggestion.id || `suggestion-${Date.now()}`;

  // Get the most recent feedback if available
  const mostRecentFeedback = useMemo(() => {
    if (!suggestion.feedback || suggestion.feedback.length === 0) {
      return null;
    }

    // Sort feedback by dateTime (newest first) and take the first one
    return [...suggestion.feedback].sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
    )[0];
  }, [suggestion.feedback]);

  // Initialize form values from the most recent feedback if available
  const initialValues: FeedbackFormValues = useMemo(
    () => ({
      message: mostRecentFeedback?.message || "",
      feedbackType:
        (mostRecentFeedback?.feedbackType as
          | "positive"
          | "neutral"
          | "negative"
          | "") || "",
      confidence:
        mostRecentFeedback?.confidence || suggestion.confidence || 0.5,
    }),
    [mostRecentFeedback, suggestion.confidence]
  );

  const handleSubmit = async (
    values: FeedbackFormValues,
    { setSubmitting }: FormikHelpers<FeedbackFormValues>
  ) => {
    if (!suggestion.id || !values.feedbackType) {
      return;
    }

    try {
      const feedback: SuggestionFeedback = {
        feedbackType: values.feedbackType,
        message: values.message,
        userId: "",
        userEmail: "",
        dateTime: new Date().toISOString(),
        confidence: values.confidence,
      };

      const updatedSuggestion = await putSuggestionFeedback(
        suggestion.ownerId || "",
        suggestion.id || "",
        feedback
      );

      if (onFeedbackSubmitted) {
        onFeedbackSubmitted(updatedSuggestion);
      }

      // We don't reset the form here to preserve the state
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="mt-3 border-t pt-3">
      <Formik
        key={formKey} // Using key to force re-initialization when suggestion changes
        initialValues={initialValues}
        validationSchema={feedbackSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // Enable reinitializing when props change
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
          submitForm,
          dirty,
        }) => (
          <Form>
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  className={`p-1.5 rounded-full ${
                    values.feedbackType === "positive"
                      ? "bg-green-100 text-green-600"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    // Set the field value and submit
                    setFieldValue("feedbackType", "positive").then(() => {
                      submitForm();
                    });
                  }}
                  title="Positive Feedback"
                >
                  {values.feedbackType === "positive" ? (
                    <span className="text-lg">👍</span>
                  ) : (
                    <HandThumbUpIcon className="h-5 w-5" />
                  )}
                </button>
                <button
                  type="button"
                  className={`p-1.5 rounded-full ${
                    values.feedbackType === "neutral"
                      ? "bg-yellow-100 text-yellow-600"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    // Set the field value and submit
                    setFieldValue("feedbackType", "neutral").then(() => {
                      submitForm();
                    });
                  }}
                  title="Neutral Feedback"
                >
                  {values.feedbackType === "neutral" ? (
                    <span className="text-lg">🤔</span>
                  ) : (
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                  )}
                </button>
                <button
                  type="button"
                  className={`p-1.5 rounded-full ${
                    values.feedbackType === "negative"
                      ? "bg-red-100 text-red-600"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    // Set the field value and submit
                    setFieldValue("feedbackType", "negative").then(() => {
                      submitForm();
                    });
                  }}
                  title="Negative Feedback"
                >
                  {values.feedbackType === "negative" ? (
                    <span className="text-lg">👎</span>
                  ) : (
                    <HandThumbDownIcon className="h-5 w-5" />
                  )}
                </button>

                {/* Confidence Picker - inline with other controls */}
                <div className="w-32 flex-shrink-0">
                  <NumberPickerField
                    label=""
                    name="confidence"
                    min={0.1}
                    max={1.0}
                    step={0.1}
                    initialValue={values.confidence}
                  />
                </div>

                <div className="flex-grow">
                  <TextInputField
                    name="message"
                    placeholder="What do you think?"
                    className="text-sm"
                  />
                </div>

                <Button
                  variant="syllabyteBlue"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  className="ml-2"
                >
                  Save Feedback
                </Button>
              </div>

              {errors.feedbackType && touched.feedbackType && (
                <div className="text-red-500 text-xs">
                  {errors.feedbackType}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
