import {
  ClipboardDocumentListIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/fields/Button";
import { NotFoundNothingHere } from "../../../components/NotFoundNothingHere";
import {
  CLASSIFICATION_TASK_TYPES,
  ContentTask,
  determineTaskType,
  getTaskStateLabel,
  SYLLABUS_TASK_TYPES,
  TASK_TYPES,
} from "../../../types/ContentTask";
import { ContentTaskCard } from "./ContentTaskCard";

export interface GroupedContentTasksListProperties {
  tasks: ContentTask[];
  onSelectTask?: (task: ContentTask) => void;
}

export const GroupedContentTasksList: React.FC<
  GroupedContentTasksListProperties
> = ({ tasks, onSelectTask }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string | undefined>();
  const [selectedTask, setSelectedTask] = useState<string | undefined>();
  const [selectedSyllabus, setSelectedSyllabus] = useState<
    string | undefined
  >();
  const [selectedCustomList, setSelectedCustomList] = useState<
    string | undefined
  >();

  // Reset type-specific filters when task type changes
  useEffect(() => {
    if (!SYLLABUS_TASK_TYPES.includes(selectedTask || "")) {
      setSelectedSyllabus(undefined);
    }
    if (!CLASSIFICATION_TASK_TYPES.includes(selectedTask || "")) {
      setSelectedCustomList(undefined);
    }
  }, [selectedTask]);

  // Determine the task type based on the task field
  const getTaskType = (task: ContentTask): string => {
    return determineTaskType(task);
  };

  // Get all available states from the tasks
  const availableStates = useMemo(() => {
    const stateSet = new Set<string>();
    tasks.forEach(task => {
      if (task.state) {
        stateSet.add(task.state.toLowerCase());
      }
    });
    return Array.from(stateSet).map(state => ({
      id: state,
      label: getTaskStateLabel(state),
      count: tasks.filter(t => t.state?.toLowerCase() === state).length,
    }));
  }, [tasks]);

  // Get all available task types from the tasks
  const availableTaskTypes = useMemo(() => {
    const taskTypeMap: Record<string, number> = {};
    tasks.forEach(task => {
      const type = getTaskType(task);
      taskTypeMap[type] = (taskTypeMap[type] || 0) + 1;
    });

    return Object.entries(taskTypeMap).map(([type, count]) => ({
      id: type,
      // Use the display name from our constants, falling back to the value if not found
      label: TASK_TYPES[type as keyof typeof TASK_TYPES] || type,
      count,
    }));
  }, [tasks]);

  // Extract all syllabi from tasks
  const syllabi = useMemo(() => {
    const syllabusSet = new Set<string>();
    tasks.forEach(task => {
      task.syllabi?.forEach(syllabus => {
        if (syllabus.title) {
          syllabusSet.add(syllabus.title);
        }
      });
    });
    return Array.from(syllabusSet);
  }, [tasks]);

  // Extract all custom lists from tasks
  const customLists = useMemo(() => {
    const listSet = new Set<string>();
    tasks.forEach(task => {
      task.customLists?.forEach(list => {
        if (list.title) {
          listSet.add(list.title);
        }
      });
    });
    return Array.from(listSet);
  }, [tasks]);

  // Determine if syllabus filters should be visible based on selected task type
  const showSyllabusFilters = useMemo(() => {
    if (!selectedTask) return true; // Show when no task type is selected
    return SYLLABUS_TASK_TYPES.includes(selectedTask);
  }, [selectedTask]);

  // Determine if custom list filters should be visible based on selected task type
  const showCustomListFilters = useMemo(() => {
    if (!selectedTask) return true; // Show when no task type is selected
    return CLASSIFICATION_TASK_TYPES.includes(selectedTask);
  }, [selectedTask]);

  // Apply filters and search
  const filteredTasks = useMemo(() => {
    let result = [...tasks];

    // Filter by state if selected
    if (selectedState) {
      result = result.filter(t => t.state === selectedState);
    }

    // Filter by task type if selected
    if (selectedTask) {
      result = result.filter(t => getTaskType(t) === selectedTask);
    }

    // Filter by syllabus if selected
    if (selectedSyllabus) {
      result = result.filter(t =>
        t.syllabi?.some(s => s.title === selectedSyllabus)
      );
    }

    // Filter by custom list if selected
    if (selectedCustomList) {
      result = result.filter(t =>
        t.customLists?.some(cl => cl.title === selectedCustomList)
      );
    }

    // Apply search query
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        t =>
          t.task?.toLowerCase().includes(query) ||
          t.message?.toLowerCase().includes(query) ||
          t.contentId?.toLowerCase().includes(query) ||
          t.id?.toLowerCase().includes(query)
      );
    }

    return result;
  }, [
    tasks,
    selectedState,
    selectedTask,
    selectedSyllabus,
    selectedCustomList,
    searchQuery,
  ]);

  const clearAllFilters = () => {
    setSelectedState(undefined);
    setSelectedTask(undefined);
    setSelectedSyllabus(undefined);
    setSelectedCustomList(undefined);
    setSearchQuery("");
  };

  // Count active filters
  const activeFilterCount = [
    selectedState,
    selectedTask,
    selectedSyllabus,
    selectedCustomList,
    searchQuery.trim() ? "search" : null,
  ].filter(Boolean).length;

  // Calculate counts for filter options with current filters applied
  const stateFilterCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    availableStates.forEach(state => {
      counts[state.id] = tasks.filter(t => {
        // Match state
        const stateMatch = t.state?.toLowerCase() === state.id;

        // Match task type if selected
        const taskTypeMatch = !selectedTask || getTaskType(t) === selectedTask;

        // Match syllabus if selected
        const syllabusMatch =
          !selectedSyllabus ||
          t.syllabi?.some(s => s.title === selectedSyllabus);

        // Match custom list if selected
        const customListMatch =
          !selectedCustomList ||
          t.customLists?.some(cl => cl.title === selectedCustomList);

        // Match search query if active
        const query = searchQuery.toLowerCase().trim();
        const searchMatch =
          !query ||
          t.task?.toLowerCase().includes(query) ||
          t.message?.toLowerCase().includes(query) ||
          t.contentId?.toLowerCase().includes(query) ||
          t.id?.toLowerCase().includes(query);

        return (
          stateMatch &&
          taskTypeMatch &&
          syllabusMatch &&
          customListMatch &&
          searchMatch
        );
      }).length;
    });

    return counts;
  }, [
    availableStates,
    tasks,
    selectedTask,
    selectedSyllabus,
    selectedCustomList,
    searchQuery,
  ]);

  // Calculate counts for task type filters with current filters applied
  const taskTypeFilterCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    availableTaskTypes.forEach(type => {
      counts[type.id] = tasks.filter(t => {
        // Match task type
        const taskTypeMatch = getTaskType(t) === type.id;

        // Match state if selected
        const stateMatch = !selectedState || t.state === selectedState;

        // Match syllabus if selected
        const syllabusMatch =
          !selectedSyllabus ||
          t.syllabi?.some(s => s.title === selectedSyllabus);

        // Match custom list if selected
        const customListMatch =
          !selectedCustomList ||
          t.customLists?.some(cl => cl.title === selectedCustomList);

        // Match search query if active
        const query = searchQuery.toLowerCase().trim();
        const searchMatch =
          !query ||
          t.task?.toLowerCase().includes(query) ||
          t.message?.toLowerCase().includes(query) ||
          t.contentId?.toLowerCase().includes(query) ||
          t.id?.toLowerCase().includes(query);

        return (
          taskTypeMatch &&
          stateMatch &&
          syllabusMatch &&
          customListMatch &&
          searchMatch
        );
      }).length;
    });

    return counts;
  }, [
    availableTaskTypes,
    tasks,
    selectedState,
    selectedSyllabus,
    selectedCustomList,
    searchQuery,
  ]);

  // Calculate counts for syllabus filters
  const syllabusFilterCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    syllabi.forEach(syllabus => {
      counts[syllabus] = tasks.filter(t => {
        // Match syllabus
        const syllabusMatch = t.syllabi?.some(s => s.title === syllabus);

        // Match state if selected
        const stateMatch = !selectedState || t.state === selectedState;

        // Match task type if selected
        const taskTypeMatch = !selectedTask || getTaskType(t) === selectedTask;

        // Match custom list if selected
        const customListMatch =
          !selectedCustomList ||
          t.customLists?.some(cl => cl.title === selectedCustomList);

        // Match search query if active
        const query = searchQuery.toLowerCase().trim();
        const searchMatch =
          !query ||
          t.task?.toLowerCase().includes(query) ||
          t.message?.toLowerCase().includes(query) ||
          t.contentId?.toLowerCase().includes(query) ||
          t.id?.toLowerCase().includes(query);

        return (
          syllabusMatch &&
          stateMatch &&
          taskTypeMatch &&
          customListMatch &&
          searchMatch
        );
      }).length;
    });

    return counts;
  }, [
    syllabi,
    tasks,
    selectedState,
    selectedTask,
    selectedCustomList,
    searchQuery,
  ]);

  // Calculate counts for custom list filters
  const customListFilterCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    customLists.forEach(list => {
      counts[list] = tasks.filter(t => {
        // Match custom list
        const customListMatch = t.customLists?.some(cl => cl.title === list);

        // Match state if selected
        const stateMatch = !selectedState || t.state === selectedState;

        // Match task type if selected
        const taskTypeMatch = !selectedTask || getTaskType(t) === selectedTask;

        // Match syllabus if selected
        const syllabusMatch =
          !selectedSyllabus ||
          t.syllabi?.some(s => s.title === selectedSyllabus);

        // Match search query if active
        const query = searchQuery.toLowerCase().trim();
        const searchMatch =
          !query ||
          t.task?.toLowerCase().includes(query) ||
          t.message?.toLowerCase().includes(query) ||
          t.contentId?.toLowerCase().includes(query) ||
          t.id?.toLowerCase().includes(query);

        return (
          customListMatch &&
          stateMatch &&
          taskTypeMatch &&
          syllabusMatch &&
          searchMatch
        );
      }).length;
    });

    return counts;
  }, [
    customLists,
    tasks,
    selectedState,
    selectedTask,
    selectedSyllabus,
    searchQuery,
  ]);

  // Count of all tasks matching current search query
  const totalFilteredCount = useMemo(() => {
    if (!searchQuery.trim()) return tasks.length;

    return tasks.filter(t => {
      const query = searchQuery.toLowerCase();
      return (
        t.task?.toLowerCase().includes(query) ||
        t.message?.toLowerCase().includes(query) ||
        t.contentId?.toLowerCase().includes(query) ||
        t.id?.toLowerCase().includes(query)
      );
    }).length;
  }, [tasks, searchQuery]);

  return (
    <div className="">
      <div className="py-4 border-b">
        <div className="flex items-center justify-between">
          <h2 className="text-xl leading-6 font-medium flex items-center">
            <ClipboardDocumentListIcon className="text-syllabyte-navy w-5 mr-2" />
            Tasks
          </h2>
          <span className="text-sm text-gray-500">
            {filteredTasks.length} of {tasks.length} tasks
          </span>
        </div>
      </div>

      {/* Search and filter controls - Always visible */}
      <div className="py-4 border-b">
        <div className="flex flex-col md:flex-row gap-3">
          {/* Search box */}
          <div className="relative flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="Search tasks..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            {searchQuery && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button onClick={() => setSearchQuery("")}>
                  <XCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                </button>
              </div>
            )}
          </div>

          {/* Clear filters button - only show if filters are active */}
          {activeFilterCount > 0 && (
            <Button
              onClick={clearAllFilters}
              variant="greyOutline"
              className="flex items-center"
              icon={<XCircleIcon className="h-5 w-5 text-gray-400" />}
            >
              Clear filters
            </Button>
          )}
        </div>

        {/* Always show filter controls */}
        <div className="mt-4 space-y-4">
          {/* State filters */}
          {availableStates.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by state:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for states */}
                <Button
                  onClick={() => setSelectedState(undefined)}
                  variant={!selectedState ? "syllabyteNavy" : "greyOutline"}
                  className="flex items-center"
                >
                  All ({totalFilteredCount})
                </Button>

                {availableStates.map(state => {
                  const isSelected = state.id === selectedState;
                  const filteredCount = stateFilterCounts[state.id] || 0;

                  // Don't show options with 0 matches
                  if (filteredCount === 0) return null;

                  return (
                    <Button
                      key={state.id}
                      onClick={() =>
                        setSelectedState(isSelected ? undefined : state.id)
                      }
                      variant={isSelected ? "syllabyteNavy" : "greyOutline"}
                      className="flex items-center"
                    >
                      {state.label} ({filteredCount})
                    </Button>
                  );
                })}
              </div>
            </div>
          )}

          {/* Task type filters */}
          {availableTaskTypes.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by task type:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for task types */}
                <Button
                  onClick={() => setSelectedTask(undefined)}
                  variant={!selectedTask ? "syllabyteNavy" : "greyOutline"}
                  className="flex items-center"
                >
                  All ({totalFilteredCount})
                </Button>

                {availableTaskTypes.map(type => {
                  const isSelected = type.id === selectedTask;
                  const filteredCount = taskTypeFilterCounts[type.id] || 0;

                  // Don't show options with 0 matches
                  if (filteredCount === 0) return null;

                  return (
                    <Button
                      key={type.id}
                      onClick={() =>
                        setSelectedTask(isSelected ? undefined : type.id)
                      }
                      variant={isSelected ? "syllabyteNavy" : "greyOutline"}
                      className="flex items-center"
                    >
                      {type.label} ({filteredCount})
                    </Button>
                  );
                })}
              </div>
            </div>
          )}

          {/* Syllabus filters - only show when relevant task types are selected */}
          {syllabi.length > 0 && showSyllabusFilters && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by syllabus:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for syllabi */}
                <Button
                  onClick={() => setSelectedSyllabus(undefined)}
                  variant={!selectedSyllabus ? "syllabyteNavy" : "greyOutline"}
                  className="flex items-center"
                >
                  All ({totalFilteredCount})
                </Button>

                {syllabi.map((syllabus, index) => {
                  const isSelected = syllabus === selectedSyllabus;
                  const filteredCount = syllabusFilterCounts[syllabus] || 0;

                  // Don't show options with 0 matches
                  if (filteredCount === 0) return null;

                  return (
                    <Button
                      key={index}
                      onClick={() =>
                        setSelectedSyllabus(isSelected ? undefined : syllabus)
                      }
                      variant={isSelected ? "syllabyteNavy" : "greyOutline"}
                      className="flex items-center"
                    >
                      {syllabus} ({filteredCount})
                    </Button>
                  );
                })}
              </div>
            </div>
          )}

          {/* Custom list filters - only show when relevant task types are selected */}
          {customLists.length > 0 && showCustomListFilters && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by custom list:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for custom lists */}
                <Button
                  onClick={() => setSelectedCustomList(undefined)}
                  variant={
                    !selectedCustomList ? "syllabyteNavy" : "greyOutline"
                  }
                  className="flex items-center"
                >
                  All ({totalFilteredCount})
                </Button>

                {customLists.map((list, index) => {
                  const isSelected = list === selectedCustomList;
                  const filteredCount = customListFilterCounts[list] || 0;

                  // Don't show options with 0 matches
                  if (filteredCount === 0) return null;

                  return (
                    <Button
                      key={index}
                      onClick={() =>
                        setSelectedCustomList(isSelected ? undefined : list)
                      }
                      variant={isSelected ? "syllabyteNavy" : "greyOutline"}
                      className="flex items-center"
                    >
                      {list} ({filteredCount})
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Tasks list */}
      <div className="py-6">
        <div className="space-y-6">
          {filteredTasks.map((task, index) => (
            <ContentTaskCard
              key={index}
              task={task}
              onSelect={onSelectTask ? () => onSelectTask(task) : undefined}
            />
          ))}
        </div>
        {filteredTasks.length === 0 && (
          <NotFoundNothingHere text="No tasks found. Try adjusting your search or filters." />
        )}
      </div>
    </div>
  );
};
