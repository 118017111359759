import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { ProgressBar } from "../../../components/fields/ProgressBar";
import { Content } from "../../../types/Content";
import {
  getTaskStateLabel,
  getTaskTypeLabel,
} from "../../../types/ContentTask";
import { getSliceTask } from "../../../util/ApiIntegration";

interface ContentUploadTaskProps {
  content: Content;
  onUploadComplete?: (contentId: string) => void;
}

export function ContentUploadTask({
  content,
  onUploadComplete,
}: ContentUploadTaskProps): JSX.Element {
  // Track if we've already called onUploadComplete to prevent duplicate calls
  const completionCalled = useRef(false);

  const isUploadComplete =
    content.uploadingDoneAt !== undefined &&
    content.uploadingDoneAt !== "0001-01-01T00:00:00";

  // Query for the main upload task
  const {
    data: uploadTask,
    isError,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["uploadTask", content.id],
    queryFn: async () => {
      if (!content.id) return null;
      try {
        const result = await getSliceTask(content.id, content.id, content.id);
        return result;
      } catch (error) {
        console.error("Error loading upload task status:", error);
        throw error;
      }
    },
    enabled: !isUploadComplete && !!content.id,
    refetchInterval: query => {
      // Stop polling if already complete
      if (isUploadComplete || completionCalled.current) return false;

      // Continue polling based on task state
      if (!query.state.data) return 3000; // Default to 3 seconds

      const state = query.state.data.state?.toLowerCase();
      return state === "inprogress" || state === "pending" || state === "ready"
        ? 3000
        : false;
    },
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: 2000, // Reduce stale time to get more updates
  });

  // Effect to handle upload completion
  useEffect(() => {
    // Trigger completion when task state is complete
    if (
      !completionCalled.current &&
      content.id &&
      onUploadComplete &&
      uploadTask?.state === "complete"
    ) {
      // Mark completion called to prevent duplicates
      completionCalled.current = true;

      // Fetch the latest content before calling the callback
      const fetchUpdatedContent = async () => {
        try {
          // Call the parent callback to refresh the content
          if (onUploadComplete && content.id) {
            onUploadComplete(content.id);
          }

          console.log(
            "Upload complete, fetching updated content for ID:",
            content.id
          );

          // If you have a direct API call to get content, you could use it here
          // const updatedContent = await getContentById(content.id);
          // Then update local state or pass to parent
        } catch (error) {
          console.error("Error fetching updated content:", error);
        }
      };

      fetchUpdatedContent();
    }
  }, [uploadTask?.state, content.id, onUploadComplete]);

  // Early exit if upload is already complete
  if (isUploadComplete) {
    return <></>;
  }

  // Only show error state or valid upload task with progress
  if (isError) {
    return (
      <div className="mt-4 text-red-500 flex items-center">
        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
        <span>Error loading upload status.</span>
      </div>
    );
  }

  // Don't show anything while loading or if no task is found
  if (isLoading || !uploadTask) {
    return <></>;
  }

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <p className="text-sm mb-2">
          {uploadTask.message || getTaskTypeLabel(uploadTask.task || "")}
        </p>
        <span className="text-xs text-gray-500">
          {getTaskStateLabel(uploadTask.state || "")}
        </span>
      </div>

      {uploadTask.state !== "broken" && (
        <ProgressBar
          progress={uploadTask.progressPercent || 0}
          max={100}
          paused={uploadTask.state === "pending"}
        />
      )}

      {uploadTask.state === "broken" && (
        <div className="text-red-500 text-sm mt-2 flex items-center">
          <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
          <span>Upload failed. Please try again.</span>
        </div>
      )}
    </div>
  );
}
