import { Outlet } from "react-router-dom";
import { NavItem } from "../../types/platform/NavItem";
import { NavBar } from "../navigation/NavBar";
import { Onboarder } from "../onboarding/Onboarder";
import { Footer } from "./Footer";

export const AppLayout = (): JSX.Element => {
  const navItems: NavItem[] = [
    { path: "/dashboard", label: "My Dashboard" },
    { path: "/content", label: "My Content" },
    { path: "/studio", label: "The Studio" },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Onboarder />
      <NavBar navItems={navItems} fullWidth={true} />
      {/* <UpSellContainer /> */}
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer variant="thin" />
    </div>
  );
};
