import clsx from "clsx";
import { CSSProperties, forwardRef, useCallback, useMemo } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { Loading } from "../Loading";

interface ButtonProperties {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  title?: string;
  to?: string;
  variant: ButtonVariants;
  isLoading?: boolean;
  loadingColor?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  className?: string;
  icon?: React.ReactNode;
  ariaExpanded?: boolean;
  styles?: CSSProperties;
}

export type ButtonVariants =
  | "syllabytePink"
  | "syllabytePinkLarge"
  | "syllabyteBlue"
  | "syllabyteBlueLarge"
  | "syllabyteGreen"
  | "syllabyteGreenLarge"
  | "syllabyteNavy"
  | "greyOutline"
  | "greyOutlineLarge"
  | "round"
  | "link"
  | "badge"
  | "badgeLight"
  | "none"
  | "greenRound";

const baseButtonStyles =
  "inline-flex justify-center border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2";

const buttonVariants: Record<ButtonVariants, string> = {
  syllabytePink: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-syllabyte-pink hover:bg-opacity-80 focus:ring-syllabyte-pink`,
  syllabytePinkLarge: `${baseButtonStyles} w-full px-8 py-3 text-base font-medium text-white bg-syllabyte-pink hover:bg-opacity-80 md:py-4 md:text-lg md:px-8 focus:ring-syllabyte-pink`,
  syllabyteBlue: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-syllabyte-blue hover:bg-opacity-80 focus:ring-syllabyte-blue`,
  syllabyteBlueLarge: `${baseButtonStyles} w-full px-8 py-3 text-base font-medium text-white bg-syllabyte-blue hover:bg-opacity-80 md:py-4 md:text-lg md:px-8 focus:ring-syllabyte-blue`,
  syllabyteGreen: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-syllabyte-green hover:bg-opacity-80 focus:ring-syllabyte-green`,
  syllabyteGreenLarge: `${baseButtonStyles} w-full px-8 py-3 text-base font-medium text-white bg-syllabyte-green hover:bg-opacity-80 md:py-4 md:text-lg md:px-8 focus:ring-syllabyte-green`,
  syllabyteNavy: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-syllabyte-navy hover:bg-opacity-80 focus:ring-syllabyte-navy`,
  greyOutline: `${baseButtonStyles} px-4 py-2 border-gray-300 text-sm font-medium bg-white hover:bg-gray-50`,
  greyOutlineLarge: `${baseButtonStyles} w-full px-8 py-3 border-gray-300 text-base font-medium bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-8 focus:ring-gray-300`,
  round:
    "p-2 border border-gray-300 bg-white shadow-sm hover:bg-gray-50 rounded-full",
  link: "inline-flex py-2 hover:text-syllabyte-blue underline",
  none: "inline-flex py-2",
  badge: `${baseButtonStyles} whitespace-nowrap capitalize inline-block px-2.5 py-0.5 rounded-md text-sm font-medium bg-syllabyte-blue text-white`,
  badgeLight: `${baseButtonStyles} whitespace-nowrap capitalize inline-block px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-syllabyte-navy`,
  greenRound:
    "p-2 border border-gray-300 bg-syllabyte-green text-white shadow-sm hover:bg-opacity-80 rounded-full",
};

// Rest of the component remains the same
const trackEvent = (
  eventCategory?: string,
  eventAction?: string,
  eventLabel?: string
) => {
  if (eventCategory && eventAction) {
    ReactGA.event({
      category: eventCategory.toLowerCase(),
      action: `${eventAction.toLowerCase()}_click`,
      label: eventLabel,
    });
  }
};

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProperties
>(
  (
    {
      children,
      onClick,
      disabled = false,
      type = "button",
      title = "",
      to,
      variant,
      isLoading = false,
      loadingColor = "white",
      eventAction,
      eventCategory,
      eventLabel,
      className: customClassName,
      icon,
      ariaExpanded,
      styles,
    }: ButtonProperties,
    reference
  ): JSX.Element => {
    const disabledClassnames = disabled ? "opacity-50 cursor-not-allowed" : "";
    const className = useMemo(
      () => clsx(buttonVariants[variant], customClassName, disabledClassnames),
      [variant, customClassName, disabledClassnames]
    );

    const handleOnClick = useCallback(() => {
      if (disabled || isLoading) return;
      onClick?.();
      trackEvent(eventCategory, eventAction, eventLabel);
    }, [
      onClick,
      disabled,
      isLoading,
      eventCategory,
      eventAction,
      eventLabel,
      variant,
    ]);

    const buttonContent = (
      <>
        {isLoading && (
          <Loading
            type={"spinner"}
            className="pr-2"
            topMargin="none"
            color={loadingColor}
          />
        )}
        {icon && <span className="mr-2">{icon}</span>} {children}
      </>
    );

    if (to) {
      const isExternal = to.startsWith("http://") || to.startsWith("https://");
      if (isExternal) {
        return (
          <a
            href={disabled ? undefined : to}
            className={className}
            onClick={handleOnClick}
            style={styles}
            aria-label={title}
            aria-busy={isLoading}
            aria-expanded={ariaExpanded}
            target="_blank"
            rel="noopener noreferrer"
            ref={reference as React.ForwardedRef<HTMLAnchorElement>}
          >
            {buttonContent}
          </a>
        );
      }
      return (
        <Link
          to={disabled ? "" : to}
          className={className}
          onClick={handleOnClick}
          style={styles}
          aria-label={title}
          aria-busy={isLoading}
          aria-expanded={ariaExpanded}
          ref={reference as React.ForwardedRef<HTMLAnchorElement>}
        >
          {buttonContent}
        </Link>
      );
    }

    return (
      <button
        ref={reference as React.ForwardedRef<HTMLButtonElement>}
        type={type}
        title={title}
        disabled={disabled || isLoading}
        onClick={handleOnClick}
        className={className}
        style={styles}
        aria-label={title}
        aria-busy={isLoading}
        aria-expanded={ariaExpanded}
      >
        {buttonContent}
      </button>
    );
  }
);
