import { Outcome } from "./Outcome";

export interface Suggestion {
  ownerId?: string;
  id?: string;

  contentOwnerId?: string;
  contentId?: string;
  sliceId?: string;
  state?: string;
  suggestionType?: string;
  startIndex?: number;
  endIndex?: number;
  originalText?: string;
  suggested?: string;
  justification?: string;
  confidence?: number;
  outcome?: Outcome;
  created?: string;
  feedback?: SuggestionFeedback[];
}

export interface SuggestionFeedback {
  userId: string;
  userEmail: string;
  dateTime: string;
  confidence?: number;
  feedbackType: string;
  message: string;
}

export const SUGGESTION_TYPES = {
  notSet: "Not Set",
  upload: "Content",
  spelling: "Spelling & Grammar",
  classify: "Classifications",
  align: "Alignment",
  "extract-outcomes": "Extract Outcomes",
  "expand-print-contractions": "Expand Print Contractions",
  "image-anaylsis": "Analyse Images",
  "copy-edit": "Copy Edit",
  "copy-develop": "Copy Develop",
  "publishing-metadata": "Extract Publishing Metadata",
};
