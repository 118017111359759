import {
  ArrowDownIcon,
  ArrowUpIcon,
  LightBulbIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useState } from "react";
import {
  CLASSIFICATION_TASK_TYPES,
  SYLLABUS_TASK_TYPES,
} from "../../types/ContentTask";
import { Suggestion, SUGGESTION_TYPES } from "../../types/Suggestion";
import { Button } from "../fields/Button";
import { NotFoundNothingHere } from "../NotFoundNothingHere";
import { SuggestionCard } from "./SuggestionCard";

export interface GroupedSuggestionListProperties {
  suggestions: Suggestion[];
  onAddSuggestion?: (suggestion: Suggestion) => void;
  onEditSuggestion?: (suggestion: Suggestion) => void;
  onFeedbackSubmitted?: (suggestion: Suggestion) => void;
  onDeleteSuggestion?: (suggestion: Suggestion) => void;
  hideConfidence?: boolean;
  hideJustification?: boolean;
  focusMode?: boolean;
}

// Sort direction type
type SortDirection = "desc" | "asc";

// Filter state type
interface FilterState {
  type?: string;
  classification?: string;
  syllabus?: string;
  learningObjectiveCode?: string;
}

export const GroupedSuggestionList: React.FC<
  GroupedSuggestionListProperties
> = ({
  suggestions,
  onAddSuggestion,
  onEditSuggestion,
  onFeedbackSubmitted,
  onDeleteSuggestion,
  hideConfidence,
  hideJustification,
  focusMode,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [confidenceSortDirection, setConfidenceSortDirection] =
    useState<SortDirection>("desc");
  const [filters, setFilters] = useState<FilterState>({});

  // Reset conditional filters when type changes
  useEffect(() => {
    if (!filters.type) {
      setFilters(prev => ({
        type: prev.type,
        // Reset the conditional filters
        classification: undefined,
        syllabus: undefined,
        learningObjectiveCode: undefined,
      }));
    }
  }, [filters.type]);

  // Utility function to extract unique values based on an accessor function
  const extractUniqueValues = <T,>(
    items: T[],
    accessor: (item: T) => string | undefined
  ): string[] => {
    const values = new Set<string>();
    items.forEach(item => {
      const value = accessor(item);
      if (value) values.add(value);
    });
    return Array.from(values);
  };

  // Group suggestions by type
  const groupedSuggestions = useMemo(() => {
    const accumulator: Record<string, Suggestion[]> = {};
    suggestions.forEach(suggestion => {
      const type = suggestion.suggestionType || "notSet";
      if (!accumulator[type]) {
        accumulator[type] = [];
      }
      accumulator[type].push(suggestion);
    });
    return accumulator;
  }, [suggestions]);

  // Get available suggestion types (only those that exist in the data)
  const availableSuggestionTypes = useMemo(() => {
    return Object.keys(groupedSuggestions).map(type => ({
      id: type,
      label: SUGGESTION_TYPES[type as keyof typeof SUGGESTION_TYPES] || type,
      count: groupedSuggestions[type].length,
    }));
  }, [groupedSuggestions]);

  // Determine which additional filters should be visible based on selected type
  const showClassificationFilters = useMemo(() => {
    if (!filters.type) return false;
    return CLASSIFICATION_TASK_TYPES.includes(filters.type);
  }, [filters.type]);

  const showSyllabusFilters = useMemo(() => {
    if (!filters.type) return false;
    return SYLLABUS_TASK_TYPES.includes(filters.type);
  }, [filters.type]);

  // Learning objective code filters are disabled
  const showLearningObjectiveCodeFilters = useMemo(() => {
    return false;
  }, []);

  // Extract all unique filter values
  const classifications = useMemo(
    () =>
      extractUniqueValues(suggestions, suggestion =>
        suggestion.suggestionType === "classify"
          ? suggestion.suggested
          : undefined
      ),
    [suggestions]
  );

  const syllabi = useMemo(
    () =>
      extractUniqueValues(
        suggestions,
        suggestion => suggestion.outcome?.syllabus
      ),
    [suggestions]
  );

  const learningObjectiveCodes = useMemo(
    () =>
      extractUniqueValues(suggestions, suggestion => suggestion.outcome?.code),
    [suggestions]
  );

  // Toggle sort direction
  const toggleSortDirection = () => {
    setConfidenceSortDirection(prev => (prev === "desc" ? "asc" : "desc"));
  };

  // Function to check if a suggestion matches the search query
  const matchesSearchQuery = (
    suggestion: Suggestion,
    query: string
  ): boolean => {
    if (!query.trim()) return true;

    const lowerQuery = query.toLowerCase();
    return !!(
      (suggestion.suggested &&
        suggestion.suggested.toLowerCase().includes(lowerQuery)) ||
      (suggestion.originalText &&
        suggestion.originalText.toLowerCase().includes(lowerQuery)) ||
      (suggestion.justification &&
        suggestion.justification.toLowerCase().includes(lowerQuery)) ||
      (suggestion.outcome?.syllabus &&
        suggestion.outcome.syllabus.toLowerCase().includes(lowerQuery)) ||
      (suggestion.outcome?.code &&
        suggestion.outcome.code.toLowerCase().includes(lowerQuery)) ||
      (suggestion.outcome?.description &&
        suggestion.outcome.description.toLowerCase().includes(lowerQuery))
    );
  };

  // Apply all filters and sorting
  const filteredAndSortedSuggestions = useMemo(() => {
    let result = [...suggestions];

    // Apply filters
    if (filters.type) {
      result = result.filter(s => s.suggestionType === filters.type);
    }

    if (filters.classification && filters.type === "classify") {
      result = result.filter(s => s.suggested === filters.classification);
    }

    if (filters.syllabus && filters.type === "align") {
      result = result.filter(s => s.outcome?.syllabus === filters.syllabus);
    }

    if (filters.learningObjectiveCode) {
      result = result.filter(
        s => s.outcome?.code === filters.learningObjectiveCode
      );
    }

    // Apply search query
    if (searchQuery.trim()) {
      result = result.filter(s => matchesSearchQuery(s, searchQuery));
    }

    // Sort by confidence
    result.sort((a, b) => {
      const confidenceA = a.confidence || 0;
      const confidenceB = b.confidence || 0;
      return confidenceSortDirection === "desc"
        ? confidenceB - confidenceA // Highest first
        : confidenceA - confidenceB; // Lowest first
    });

    return result;
  }, [suggestions, filters, searchQuery, confidenceSortDirection]);

  // Reset all filters
  const clearAllFilters = () => {
    setFilters({});
    setSearchQuery("");
  };

  // Count active filters for UI display
  const activeFilterCount =
    Object.values(filters).filter(Boolean).length +
    (searchQuery.trim() ? 1 : 0);

  // Handler for feedback submitted
  function handleFeedbackSubmitted(suggestion: Suggestion): void {
    if (onFeedbackSubmitted) {
      onFeedbackSubmitted(suggestion);
    }
  }

  // Generic function to calculate filter counts for any filter type
  const calculateFilterCounts = (
    items: string[],
    filterType: keyof FilterState,
    matchPredicate: (suggestion: Suggestion, item: string) => boolean
  ): Record<string, number> => {
    const counts: Record<string, number> = {};

    items.forEach(item => {
      counts[item] = suggestions.filter(s => {
        // Apply all other current filters except the one we're counting for
        const otherFiltersMatch = Object.entries(filters)
          .filter(([key]) => key !== filterType)
          .every(([key, value]) => {
            if (!value) return true;

            switch (key) {
              case "type":
                return s.suggestionType === value;
              case "classification":
                return s.suggested === value;
              case "syllabus":
                return s.outcome?.syllabus === value;
              case "learningObjectiveCode":
                return s.outcome?.code === value;
              default:
                return true;
            }
          });

        // Match the specific item for this filter
        const itemMatch = matchPredicate(s, item);

        // Match search query if active
        const searchMatch = matchesSearchQuery(s, searchQuery);

        return otherFiltersMatch && itemMatch && searchMatch;
      }).length;
    });

    return counts;
  };

  // Calculate counts for each filter type
  const typeFilterCounts = useMemo(() => {
    return calculateFilterCounts(
      availableSuggestionTypes.map(t => t.id),
      "type",
      (s, typeId) => s.suggestionType === typeId
    );
  }, [availableSuggestionTypes, suggestions, filters, searchQuery]);

  const classificationFilterCounts = useMemo(() => {
    return calculateFilterCounts(
      classifications,
      "classification",
      (s, classification) =>
        s.suggestionType === "classify" && s.suggested === classification
    );
  }, [classifications, suggestions, filters, searchQuery]);

  const syllabusFilterCounts = useMemo(() => {
    return calculateFilterCounts(
      syllabi,
      "syllabus",
      (s, syllabus) => s.outcome?.syllabus === syllabus
    );
  }, [syllabi, suggestions, filters, searchQuery]);

  const learningObjectiveCodeFilterCounts = useMemo(() => {
    return calculateFilterCounts(
      learningObjectiveCodes,
      "learningObjectiveCode",
      (s, code) => s.outcome?.code === code
    );
  }, [learningObjectiveCodes, suggestions, filters, searchQuery]);

  // Count of all suggestions matching current search query
  const totalFilteredCount = useMemo(() => {
    if (!searchQuery.trim()) return suggestions.length;
    return suggestions.filter(s => matchesSearchQuery(s, searchQuery)).length;
  }, [suggestions, searchQuery]);

  // Count of suggestions for current type matching all other filters
  const typeFilteredCount = useMemo(() => {
    if (!filters.type) return totalFilteredCount;

    return suggestions.filter(s => {
      // Type match
      const typeMatch = s.suggestionType === filters.type;

      // Search match
      const searchMatch = matchesSearchQuery(s, searchQuery);

      return typeMatch && searchMatch;
    }).length;
  }, [suggestions, filters.type, searchQuery, totalFilteredCount]);

  // Generic filter button component
  const FilterButton = ({
    filterType,
    value,
    label,
    count,
    onClick,
  }: {
    filterType: keyof FilterState;
    value?: string;
    label: string;
    count: number;
    onClick: () => void;
  }) => {
    const isSelected = filters[filterType] === value;

    return (
      <Button
        onClick={onClick}
        variant={isSelected ? "syllabyteNavy" : "greyOutline"}
        className="flex items-center"
      >
        {label} ({count})
      </Button>
    );
  };

  return (
    <div className="">
      <div className="py-4 border-b">
        <div className="flex items-center justify-between">
          <h2 className="text-xl leading-6 font-medium flex items-center">
            <LightBulbIcon className="text-syllabyte-navy w-5 mr-2" />
            Suggestions
          </h2>
          <span className="text-sm text-gray-500">
            {filteredAndSortedSuggestions.length} of {suggestions.length}{" "}
            suggestions
          </span>
        </div>
      </div>

      {/* Search and sort controls - Always visible */}
      <div className="py-4 border-b">
        <div className="flex flex-col md:flex-row gap-3">
          {/* Search box */}
          <div className="relative flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="Search suggestions..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            {searchQuery && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button onClick={() => setSearchQuery("")}>
                  <XCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                </button>
              </div>
            )}
          </div>

          {/* Sort button for confidence */}
          <Button
            onClick={toggleSortDirection}
            variant="greyOutline"
            className="flex items-center"
            title={`Sort by confidence: ${confidenceSortDirection === "desc" ? "Highest first" : "Lowest first"}`}
            icon={
              confidenceSortDirection === "desc" ? (
                <ArrowDownIcon className="h-5 w-5" />
              ) : (
                <ArrowUpIcon className="h-5 w-5" />
              )
            }
          >
            Confidence
          </Button>

          {/* Clear filters button - only show if filters are active */}
          {activeFilterCount > 0 && (
            <Button
              onClick={clearAllFilters}
              variant="greyOutline"
              className="flex items-center"
              icon={<XCircleIcon className="h-5 w-5 text-gray-400" />}
            >
              Clear filters
            </Button>
          )}
        </div>

        {/* Filter controls - Always visible */}
        <div className="mt-4 space-y-4">
          {/* Type filters */}
          {availableSuggestionTypes.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by type:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for types */}
                <FilterButton
                  filterType="type"
                  value={undefined}
                  label="All"
                  count={totalFilteredCount}
                  onClick={() =>
                    setFilters(prev => ({ ...prev, type: undefined }))
                  }
                />

                {availableSuggestionTypes.map(type => (
                  <FilterButton
                    key={type.id}
                    filterType="type"
                    value={type.id}
                    label={type.label}
                    count={typeFilterCounts[type.id] || 0}
                    onClick={() =>
                      setFilters(prev => ({
                        ...prev,
                        type: prev.type === type.id ? undefined : type.id,
                      }))
                    }
                  />
                ))}
              </div>
            </div>
          )}

          {/* Classification filters - only show when "classify" type is selected */}
          {filters.type &&
            showClassificationFilters &&
            classifications.length > 0 && (
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  Filter by classification:
                </h3>
                <div className="flex flex-wrap gap-2">
                  {/* "All" option for classifications */}
                  <FilterButton
                    filterType="classification"
                    value={undefined}
                    label="All"
                    count={typeFilteredCount}
                    onClick={() =>
                      setFilters(prev => ({
                        ...prev,
                        classification: undefined,
                      }))
                    }
                  />

                  {classifications.map((classification, index) => {
                    const filteredCount =
                      classificationFilterCounts[classification] || 0;

                    // Don't show options with 0 matches
                    if (filteredCount === 0) return null;

                    return (
                      <FilterButton
                        key={index}
                        filterType="classification"
                        value={classification}
                        label={classification}
                        count={filteredCount}
                        onClick={() =>
                          setFilters(prev => ({
                            ...prev,
                            classification:
                              prev.classification === classification
                                ? undefined
                                : classification,
                          }))
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}

          {/* Syllabus filters - only show when appropriate type is selected */}
          {filters.type && showSyllabusFilters && syllabi.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Filter by syllabus:
              </h3>
              <div className="flex flex-wrap gap-2">
                {/* "All" option for syllabi */}
                <FilterButton
                  filterType="syllabus"
                  value={undefined}
                  label="All"
                  count={typeFilteredCount}
                  onClick={() =>
                    setFilters(prev => ({ ...prev, syllabus: undefined }))
                  }
                />

                {syllabi.map((syllabus, index) => {
                  const filteredCount = syllabusFilterCounts[syllabus] || 0;

                  // Don't show options with 0 matches
                  if (filteredCount === 0) return null;

                  return (
                    <FilterButton
                      key={index}
                      filterType="syllabus"
                      value={syllabus}
                      label={syllabus}
                      count={filteredCount}
                      onClick={() =>
                        setFilters(prev => ({
                          ...prev,
                          syllabus:
                            prev.syllabus === syllabus ? undefined : syllabus,
                        }))
                      }
                    />
                  );
                })}
              </div>
            </div>
          )}

          {/* Learning Objective Code filters - new filter type */}
          {filters.type &&
            showLearningObjectiveCodeFilters &&
            learningObjectiveCodes.length > 0 && (
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  Filter by learning objective code:
                </h3>
                <div className="flex flex-wrap gap-2">
                  {/* "All" option for learning objective codes */}
                  <FilterButton
                    filterType="learningObjectiveCode"
                    value={undefined}
                    label="All"
                    count={typeFilteredCount}
                    onClick={() =>
                      setFilters(prev => ({
                        ...prev,
                        learningObjectiveCode: undefined,
                      }))
                    }
                  />

                  {learningObjectiveCodes.map((code, index) => {
                    const filteredCount =
                      learningObjectiveCodeFilterCounts[code] || 0;

                    // Don't show options with 0 matches
                    if (filteredCount === 0) return null;

                    return (
                      <FilterButton
                        key={index}
                        filterType="learningObjectiveCode"
                        value={code}
                        label={code}
                        count={filteredCount}
                        onClick={() =>
                          setFilters(prev => ({
                            ...prev,
                            learningObjectiveCode:
                              prev.learningObjectiveCode === code
                                ? undefined
                                : code,
                          }))
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </div>

      {/* Suggestions list */}
      <div className="py-6">
        <div className="space-y-6">
          {filteredAndSortedSuggestions.map((suggestion, index) => (
            <SuggestionCard
              key={index}
              suggestion={suggestion}
              onAddSuggestion={onAddSuggestion}
              onEditSuggestion={onEditSuggestion}
              onDeleteSuggestion={onDeleteSuggestion}
              hideConfidence={hideConfidence}
              hideJustification={hideJustification}
              focusMode={focusMode}
              onFeedbackSubmitted={handleFeedbackSubmitted}
            />
          ))}
        </div>
        {filteredAndSortedSuggestions.length === 0 && (
          <NotFoundNothingHere text="No suggestions found. Try adjusting your search or filters." />
        )}
      </div>
    </div>
  );
};
